/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Grid, Stack, Tooltip, Typography, Stepper, Step, StepLabel } from '@mui/material';

import { CalibrationInProgressModal, FormikTextfield, GenericCard, OverflowText } from '@components/common';
import { IrFileUploader, IrCalibrationInstruction, IrDeviceSpecificationInfo } from '@components/ir-portal';
import { alertService, notificationService } from '@services';
import { mainTitleSpacer, Tr } from '@utils';
import { useDeviceFileHook } from './DeviceFileHook';
import { IRecalibrationType } from './DeviceUtils';
import { useService } from '@hooks';
import { ReactComponent as Counter1 } from '@material-symbols/svg-600/outlined/counter_1-fill.svg';
import { ReactComponent as Counter2 } from '@material-symbols/svg-600/outlined/counter_2-fill.svg';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '@store/slices/common/common.slice';
import { PortalPageRoutes } from '@models/router';
import { IrDevice, irDeviceTypes, irAccessoryService, irDeviceService } from '@services/ir';
import { RxUtils } from '@utils/Rx';
import { useLocation } from 'react-router-dom';

export enum AddDeviceExpectedErrors {
	OLD_FILE_ERROR = 200009,
}
interface IAddDeviceProps {
	deviceData?: IRecalibrationType;
}

const iDData = {
	deviceId: 0,
	accessoryName: '',
	deviceData: {
		name: '',
		manufacturer: '',
		id: 0,
	},
};

type FormType = 'none' | 'newDevice' | 'recalibrate' | 'newCalibration' | 'healthCheck';

const formikStyle = {
	marginBottom: '1rem',
};

// TO DO: What is that? Do not use hardcoded string list
const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

// TO DO: SOLID PRINCIPES
export const IrAddDevice: FC<IAddDeviceProps> = ({ deviceData }) => {
	const [rawFiles, setRawFiles] = useState<File[]>([]);
	const { files, removeAllFiles } = useDeviceFileHook(rawFiles);
	const [isCalibrationProgress, setIsCalibrationProgress] = useState(false);
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = useState(0);
	const [addNewCalibration, setAddNewCalibration] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const { t } = useTranslation('irportal');
	const dispatch = useDispatch();
	const params = { deviceKey: irDeviceTypes.ALL };
	const searchParams = new URLSearchParams(params);
	const { data: deviceNamesResponse } = useService(() => irDeviceService.getDevices(searchParams));
	const { state } = useLocation();
	Object.assign(iDData, state); // What is That?

	const formik = useFormik<Extendable<{ deviceName: string; calibrationName: string; type: FormType; idOfUsedStandard: string }>>({
		initialValues: {
			calibrationName: '',
			deviceName: '',
			idOfUsedStandard: '',
			type: deviceData?.calibrationData ? 'none' : 'newDevice',
		},
		validationSchema: yup.object({
			deviceName: yup
				.string()
				.trim()
				.max(256)
				.required(t('device-management.field-error.spectrometer-name'))
				.when('type', {
					is: (type) => type === 'newDevice',
					then: (schema) =>
						schema.notOneOf(
							(deviceNamesResponse ?? []).map((it) => it.deviceName),
							t('device-management.field-error.device-name-not-unique'),
						),
					otherwise: (schema) => schema,
				}),
			calibrationName: yup.string().trim().max(256).required(t('device-management.field-error.manufacturer-name')),
		}),
		initialTouched: {
			calibrationName: false,
			deviceName: false,
		},

		onSubmit: () => {
			notificationService.clear();
			RxUtils.promisify(
				irDeviceService.addDevice(formik.values.deviceName, formik.values.calibrationName),
				(device: IrDevice) => {
					navigate(`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.GO_TO_DEVICE}/${device.id}`, {
						state: {
							deviceName: formik.values.deviceName,
							manufacturerName: formik.values.calibrationName,
							deviceId: device.id,
						},
					});
				},
				(err) => {
					notificationService.sendError(err.Message);
				},
			);
		},
	});

	useEffect(() => {
		formik.validateForm();
	}, []);

	useEffect(() => {
		dispatch(setBreadcrumb({ name: deviceData?.calibrationData?.name, ':id': deviceData?.calibrationData?.id }));

		if (deviceData && deviceData.calibrationData) {
			const { name } = deviceData.calibrationData;
			formik.setFieldValue('deviceName', name);
		}
	}, [deviceData, files]);

	const handleBack = () => {
		if (activeStep === 0) {
			navigate(-1);
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		}
	};

	const getLeftSideHeader = () => {
		if (addNewCalibration) return <Tr.IrPortal path="device-management.add-calibration" />;

		return '';
	};

	const getLeftSideExplanation = () => {
		if (addNewCalibration) return <Tr.IrPortal path="device-management.add-device-calibration-message" />;

		return '';
	};

	const completeCalibrationProcess = () => {
		RxUtils.promisify(
			irAccessoryService.calibrationComplete(iDData.deviceId),
			() => {
				setIsCalibrationProgress(false);
				navigate(`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.GO_TO_DEVICE}/${iDData.deviceData.id}`, {
					state: {
						deviceName: iDData.deviceData.name,
						manufacturerName: iDData.deviceData.manufacturer,
						deviceId: iDData.deviceData.id,
					},
				});
			},
			(err) => {
				notificationService.sendError(err.Message);
			},
		);
	};

	const onUploadedFileChange = (fileType: string, file?: File[], polyStyreneStandardId?: string) => {
		if (!file || '' || file?.length < 1) {
			notificationService.sendError(t('file-invalid'));
			return;
		}
		RxUtils.promisify(
			irAccessoryService.uploadFile(iDData.deviceId, file[0], fileType, polyStyreneStandardId || ''),
			() => {
				setIsDirty(true);
				notificationService.sendSuccess(t('new-analysis.success-file-upload'));
			},
			(err) => {
				setIsDirty(false);
				notificationService.sendError(err.Message);
			},
		);
	};

	const getInProgressModalContent = () => {
		if (deviceData) {
			return {
				title: t('device-management.calibrating'),
				message: t('device-management.calibration-progress.message'),
				subtitle: t('device-management.calibration-progress.title'),
			};
		}
		return {
			title: t('device-management.creating'),
			message: t('device-management.add-device-progress.message'),
			subtitle: t('device-management.add-device-progress.title'),
		};
	};
	return (
		<form onSubmit={formik.handleSubmit}>
			<Stack direction="row" justifyContent="space-between" sx={{ marginBottom: mainTitleSpacer }}>
				<OverflowText variant="h1" sx={{ color: 'text.secondary', width: '100%', maxWidth: 'calc(100% - 25rem)' }}>
					{!deviceData ? (
						<Tr.IrPortal path="device-management.add-device" />
					) : (
						<Typography variant="h1" sx={{ color: 'text.secondary' }}>
							{iDData.deviceData.name}
						</Typography>
					)}
				</OverflowText>

				{!deviceData ? (
					<Stack direction="row" alignSelf="flex-end">
						<Button
							variant="outlined"
							sx={{ marginRight: '1rem' }}
							onClick={() => {
								if (addNewCalibration) {
									formik.setFieldValue('type', 'none');
									setAddNewCalibration(false);
									return;
								}

								files.length === 0
									? navigate(-1)
									: alertService.send({
											content: <Tr.IrPortal path="device-management.cancel-content" />,
											titleText: <Tr.IrPortal path="device-management.cancel-title" />,
											onConfirm: () => {
												setRawFiles([]);
												removeAllFiles();
												navigate(-1);
											},
										});
							}}
						>
							<Tr.IrPortal path="device-management.cancel" />
						</Button>

						<Tooltip
							disableHoverListener={formik.isValid}
							title={!addNewCalibration ? t('device-management.tooltip') : ''}
							sx={{ backgroundColor: '#000000' }}
						>
							<Button
								disabled={!(formik.isValid && formik.dirty)}
								type="submit"
								variant="contained"
								disableElevation
								sx={{ backgroundColor: 'primary.main' }}
							>
								<Tr.IrPortal path="device-management.create-device" />
							</Button>
						</Tooltip>
					</Stack>
				) : (
					<Stack direction="row" alignSelf="flex-end">
						<Button variant="outlined" sx={{ marginRight: '1rem' }} onClick={handleBack}>
							<Tr.IrPortal path="back" />
						</Button>
						<Button
							variant="contained"
							disableElevation
							sx={{ backgroundColor: 'primary.main' }}
							disabled={isDirty === false}
							onClick={() => {
								setIsDirty(false);
								if (activeStep === steps.length - 1) {
									setTimeout(() => {
										setIsCalibrationProgress(true);
										completeCalibrationProcess();
									}, 50);

									return;
								} else {
									setActiveStep((prevActiveStep) => prevActiveStep + 1);
									return;
								}
							}}
						>
							{activeStep === steps.length - 1 ? (
								<Tr.IrPortal path="device-management.calibrate" />
							) : (
								<Tr.IrPortal path="device-management.next" />
							)}
						</Button>
					</Stack>
				)}
			</Stack>
			{isCalibrationProgress && <CalibrationInProgressModal {...getInProgressModalContent()} />}
			<Grid data-testid="device-wrapper-id" container columnSpacing={2}>
				<Grid item xs={6} height={1}>
					<GenericCard sx={{ height: '100%' }}>
						{!deviceData || addNewCalibration ? (
							<Stack>
								{addNewCalibration && (
									<Stack paddingBottom={1} marginBottom={3} borderBottom="1px solid" borderColor="grey.200">
										<Typography variant="h4" sx={{ marginBottom: 2, textTransform: 'none' }} color="primary.main">
											{getLeftSideHeader()}
										</Typography>
										<Typography variant="body2" sx={{ marginBottom: addNewCalibration ? 1 : 1.5 }}>
											{getLeftSideExplanation()}
										</Typography>
									</Stack>
								)}

								{!addNewCalibration && (
									<Stack marginBottom={3}>
										<Stack direction="row">
											<Counter1 width={24} height={24} fill="#01884C" />
											<Typography variant="h5" color="text.primary" marginLeft={1}>
												{t('device-management.spectrometer-name')}
											</Typography>
										</Stack>
										<Typography variant="pg-m" sx={{ marginY: 1.5 }}>
											{t('device-management.spectrometer-name-caption')}
										</Typography>
										<FormikTextfield
											sx={formikStyle}
											formikApi={formik}
											name="deviceName"
											inputProps={{ maxLength: 256 }}
											required
											placeholder={t('device-management.device-name-placeholder')}
											onChange={(event) => formik.setFieldValue('deviceName', event.target.value)}
										/>
									</Stack>
								)}

								<Stack>
									<Stack direction="row">
										<Counter2 width={24} height={24} fill="#01884C" />
										<Typography variant="h5" color="text.primary" marginLeft={1}>
											{t('device-management.manufacturer')}
										</Typography>
									</Stack>
									<Typography variant="pg-m" sx={{ marginY: 1.5 }}>
										{t('device-management.manufacturer-name-caption')}
									</Typography>
									<FormikTextfield
										sx={formikStyle}
										formikApi={formik}
										name="calibrationName"
										required
										placeholder={t('device-management.manufacturer-name-placeholder')}
										containerProps={{ width: 1 }}
										inputProps={{ maxLength: 256 }}
									/>
								</Stack>
							</Stack>
						) : (
							<Stack>
								<Typography variant="h4" sx={{ marginBottom: '1.5rem', textTransform: 'none' }} color="primary.main">
									<Tr.IrPortal path="device-management.calibrate-device" />
								</Typography>
								<Typography variant="body2" sx={{ marginBottom: '1rem' }}>
									<Tr.IrPortal path="device-management.accessory-calibration-message" />
								</Typography>
								<Typography variant="h4" sx={{ marginBottom: '1rem', color: 'text.secondary' }}>
									{iDData.accessoryName}
								</Typography>
								<Stepper activeStep={activeStep}>
									{steps.map((label) => {
										return (
											<Step key={label}>
												<StepLabel></StepLabel>
											</Step>
										);
									})}
								</Stepper>
								{/* TO DO: SEPERATE THEM  */}
								{activeStep === 0 ? (
									<>
										<Typography variant="h5" sx={{ marginTop: 3, textTransform: 'none' }}>
											<Tr.IrPortal path="device-management.instruction-for-calibration" />
										</Typography>
										<Typography variant="body1" sx={{ marginTop: 2, textTransform: 'none' }}>
											<Tr.IrPortal path="device-management.irinstruction-for-callibration" />
										</Typography>
										<Typography variant="body1" sx={{ marginTop: 2, textTransform: 'none' }}>
											<Tr.IrPortal path="device-management.irinstructionstage1-for-callibration" />
										</Typography>
										<Stack direction="row">
											<Typography className="required" variant="h5" color="text.primary" marginTop={2}>
												Upload Files
											</Typography>
										</Stack>
										<Stack direction="row">
											<IrCalibrationInstruction isCheckCalibration={true} />
										</Stack>
										<Stack>
											<IrFileUploader
												disable={false}
												multiple={false}
												onUploadedFileChange={(data) => onUploadedFileChange('EmptyCellFile', data as File[], '')}
												sx={{ marginTop: 3 }}
											/>
										</Stack>
									</>
								) : activeStep === 1 ? (
									<>
										<Typography variant="h5" sx={{ marginTop: 3, textTransform: 'none' }}>
											<Tr.IrPortal path="device-management.instruction-for-calibration" />
										</Typography>
										<Typography variant="body1" sx={{ marginTop: 2, textTransform: 'none' }}>
											<Tr.IrPortal path="device-management.irinstruction-for-callibration" />
										</Typography>
										<Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textTransform: 'none' }}>
											<Tr.IrPortal path="device-management.irinstructionstage2-for-callibration" />
										</Typography>
										<Stack>
											<Typography variant="body2" sx={{ marginY: 1.5 }}>
												{t('device-management.idofused-Standard-caption')}
											</Typography>
											<FormikTextfield
												sx={formikStyle}
												formikApi={formik}
												name="idOfUsedStandard"
												containerProps={{ width: 1 }}
												inputProps={{ maxLength: 256 }}
												onChange={(event) => formik.setFieldValue('idOfUsedStandard', event.target.value?.trim())}
											/>
										</Stack>
										<Stack direction="row">
											<Typography className="required" variant="h5" color="text.primary" marginTop={2}>
												Upload Files
											</Typography>
										</Stack>
										<Stack direction="row">
											<IrCalibrationInstruction isCheckCalibration={true} />
										</Stack>
										<Stack>
											<IrFileUploader
												disable={false}
												multiple={false}
												onUploadedFileChange={(data) =>
													onUploadedFileChange('PolyStyreneFile', data as File[], formik.values.idOfUsedStandard)
												}
												sx={{ marginTop: 3 }}
											/>
										</Stack>
									</>
								) : (
									<>
										<Typography variant="h5" sx={{ marginTop: 3, textTransform: 'none' }}>
											<Tr.IrPortal path="device-management.instruction-for-calibration" />
										</Typography>
										<Typography variant="body1" sx={{ marginTop: 2, textTransform: 'none' }}>
											<Tr.IrPortal path="device-management.irinstruction-for-callibration" />
										</Typography>
										<Typography variant="body1" sx={{ marginTop: 3, marginBottom: 2, textTransform: 'none' }}>
											<Tr.IrPortal path="device-management.irinstructionstage3-for-callibration" />
										</Typography>

										<Stack direction="row">
											<Typography className="required" variant="h5" color="text.primary" marginTop={2}>
												Upload Files
											</Typography>
										</Stack>
										<Stack direction="row">
											<IrCalibrationInstruction isCheckCalibration={true} />
										</Stack>
										<Stack>
											<IrFileUploader
												disable={false}
												multiple={false}
												onUploadedFileChange={(data) => onUploadedFileChange('PropanolFile', data as File[], '')}
												sx={{ marginTop: 3 }}
											/>
										</Stack>
									</>
								)}
							</Stack>
						)}
					</GenericCard>
				</Grid>
				<Grid item xs={6} height={1}>
					{(files.length > 0 || deviceData) && (
						<GenericCard sx={{ height: '100%' }}>
							<Typography variant="h4" sx={{ marginBottom: '1.5rem', textTransform: 'none' }} color="primary.main">
								<Tr.IrPortal path="device-management.device-specification" />
							</Typography>

							<IrDeviceSpecificationInfo title={t('device-management.device-name')} value={iDData.deviceData.name || ''} />
							<IrDeviceSpecificationInfo
								title={t('device-management.manufacturer')}
								value={iDData.deviceData.manufacturer || ''}
							/>
						</GenericCard>
					)}
				</Grid>
			</Grid>
		</form>
	);
};
