import { FC, ReactNode } from 'react';
import { FormikApiType, FormikTextfield } from '../FormikTextfield';
import { Grid, Stack, Typography } from '@mui/material';
import phoneCodes from '@assets/data/phone-codes.json';
import { useTranslation } from 'react-i18next';
import { getPhoneCode, getPhoneNumber, onPhoneCodeChange, onPhoneNumberChange } from './FormikPhoneField.logic';
import { FormikAutoComplete } from '../FormikAutoComplete';
interface IFormikPhoneFieldProps {
	formik?: FormikApiType;
	title?: ReactNode | string;
	disabled?: boolean;
}
const fullWidth = { width: 1 };

const phoneCodeList = phoneCodes.map(({ name, dial_code }) => ({ label: `${name} (${dial_code})`, value: dial_code }));
type PhoneOption = (typeof phoneCodeList)[0];
export const FormikPhoneField: FC<IFormikPhoneFieldProps> = ({ formik, title, disabled = false }) => {
	const { t } = useTranslation('admin');

	return (
		<Stack>
			{title && (
				<Typography
					variant="label-s"
					marginBottom={1.25}
					className={disabled ? '' : 'required'}
					color={disabled ? 'grey.200' : 'grey.800'}
				>
					{title}
				</Typography>
			)}
			<Grid container spacing={3}>
				<Grid item xs={4}>
					<FormikAutoComplete
						inputProps={{
							'data-testid': 'phone-code-id',
						}}
						formik={formik}
						select={false}
						name="phoneCode"
						placeholder={t('organization.country')}
						variant="outlined"
						required
						options={phoneCodeList}
						disabled={disabled}
						value={
							formik?.values.phoneNumber
								? { label: getPhoneCode(formik.values.phoneNumber), value: getPhoneCode(formik.values.phoneNumber) }
								: null
						}
						autocompleteProps={{
							isOptionEqualToValue: (option: PhoneOption, value: PhoneOption) => option.value === value.value,
							disablePortal: true,
							disableClearable: !getPhoneCode(formik?.values.phoneNumber ?? ''),
						}}
						onChange={(e) => {
							formik?.setFieldValue('phoneNumber', onPhoneCodeChange(formik.values.phoneNumber, e?.target.value));
						}}
						onSelectionChange={(data: PhoneOption) => {
							formik?.setFieldValue('phoneNumber', onPhoneCodeChange(formik.values.phoneNumber, data?.value));
						}}
					/>
				</Grid>

				<Grid item xs={8}>
					<FormikTextfield
						formikApi={formik}
						name="phoneDigits"
						type="number"
						value={getPhoneNumber(formik?.values?.phoneNumber)}
						required
						disabled={disabled}
						placeholder={t('organization.phone-number')}
						inputProps={{ 'data-testid': 'form-phone-number-id' }}
						variant="outlined"
						containerProps={fullWidth}
						onChange={(e) => {
							formik?.setFieldValue('phoneNumber', onPhoneNumberChange(formik.values.phoneNumber, e.target.value));
						}}
					/>
				</Grid>
			</Grid>
		</Stack>
	);
};
