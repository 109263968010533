import { FormikApiType } from '@components/common';
import { IrDRMDetail, IrSubstanceList } from '@components/ir-portal';
import { AddFromFavoritesButton } from '@components/ir-portal/IrFavorites';
import { useIrAnalysis, useIrDRMDetail } from '@hooks';
import { IrAnalysisType } from '@models';
import { AnalysisTargetType } from '@models/analysis';
import { Divider, Stack, Typography } from '@mui/material';
import { IrSubstance } from '@services';
import { Tr } from '@utils';
import { FC, createRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IrAnalysisLibrarySelect } from './IrAnalysisLibrarySelect';
interface IAnalysisFavorite {
	addedSubstances: IrSubstance[] | undefined;
	onRemoveSubstance: (drmCode: number) => void;
	id: number;
	formik: FormikApiType;
	onAddErmOrSubstance: (ermId: number) => void;
	onSubstanceListRefresh: () => void;
}

export const AnalysisFavorite: FC<IAnalysisFavorite> = ({
	addedSubstances,
	onRemoveSubstance,
	onAddErmOrSubstance,
	onSubstanceListRefresh,
	formik,
}) => {
	const [displayedAddedSubstanceList, setDisplayedAddedSubstanceList] = useState<IrSubstance[]>([]);
	const { resetIrDetail: resetDrmDetail, setIrDRMDetail, ...drmProps } = useIrDRMDetail();
	const { isAnalysisEditable } = useIrAnalysis();

	const { t } = useTranslation('irportal');
	//TODO change this
	const onRemoveAddedSubstance = (referenceMaterialId: number) => {
		resetDrmDetail();
		onRemoveSubstance(referenceMaterialId);
	};

	const onFavoriteSelected = (favoriteId) => {
		onAddErmOrSubstance(favoriteId);
	};

	useEffect(() => {
		if (addedSubstances?.length) {
			formik?.setFieldValue('targetType', AnalysisTargetType.TARGETED);
		}
		setDisplayedAddedSubstanceList(addedSubstances || []);
	}, [addedSubstances]);

	useEffect(() => {
		/// reset
		if (displayedAddedSubstanceList?.length) {
			displayedAddedSubstanceList.splice(0, displayedAddedSubstanceList.length);
			formik?.setFieldValue('referenceId', null);
			formik?.values.selectedImpurity && formik?.setFieldValue('selectedImpurity', null);
		}
	}, [formik.values.type]);

	const drmDetailRef = createRef<HTMLDivElement>();
	return (
		<Stack data-testid="analysis-favorite">
			<Stack direction="row" justifyContent="space-between">
				<Stack>
					<Typography variant="h4" color="primary.main">
						<Tr.IrPortal path="new-analysis.add-reference" />
					</Typography>
				</Stack>
				<Stack>
					{(formik?.values.type === IrAnalysisType.PURITYCHECK || formik?.values.type === IrAnalysisType.QUANTIFICATION) &&
						formik?.values?.selectedLibrary?.length > 0 && (
							<Stack direction="row" sx={{ marginLeft: 'auto' }}>
								<AddFromFavoritesButton
									type={formik?.values.type}
									library={formik?.values.selectedLibrary}
									onFavoriteSelect={onFavoriteSelected}
									isDisabled={!isAnalysisEditable}
								/>
							</Stack>
						)}
				</Stack>
			</Stack>
			<Stack mt={3}>
				<IrAnalysisLibrarySelect formik={formik} displayedAddedSubstanceList={displayedAddedSubstanceList} />
			</Stack>

			{displayedAddedSubstanceList?.length &&
			!(formik?.values.type === IrAnalysisType.IDENTIFICATION) &&
			formik?.values.selectedLibrary ? (
				<>
					<Stack data-testid="drm-list-wrapper-id" direction="column" sx={{ mt: 1 }}>
						<Stack direction="row" alignItems="center">
							<Typography variant="h5">
								<Tr.IrPortal path="new-analysis.your-references" />
							</Typography>
						</Stack>
						<Divider sx={{ mt: 1 }} />

						<IrSubstanceList
							substanceList={displayedAddedSubstanceList}
							type="remove"
							actionLabel={t('drm-detail.remove-from-analysis')}
							onSubstanceListRefresh={onSubstanceListRefresh}
							disabled={!isAnalysisEditable}
							onSubstanceRemove={(drmCode: number) => onRemoveAddedSubstance(drmCode)}
							onSubstanceDetail={(drmVisibility: boolean, detailId: number) =>
								setIrDRMDetail({
									actionLabel: t('drm-detail.remove-from-analysis'),
									// TODO checking for substanceID here. Should be checking for referenceMaterial ID. But i think Adding and removal will be done by BE
									drmId: displayedAddedSubstanceList.find((e) => e.id === detailId)?.id ?? detailId,
									onHandleAction: (dmrId) => onRemoveAddedSubstance(+dmrId),
									visible: drmVisibility,
								})
							}
							itemSx={{ padding: '1rem 0' }}
							dataTestId="substance-added-drms-wrapper"
						/>
						<Divider sx={{ mb: 2 }} />
					</Stack>
				</>
			) : null}
			{useMemo(
				() => (
					<IrDRMDetail onCloseClick={() => resetDrmDetail()} ref={drmDetailRef} {...drmProps} />
				),
				[drmProps],
			)}
		</Stack>
	);
};
