import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getRegisterValidationSchema: (t: TFunction) => yup.AnyObjectSchema = (t) =>
	yup.object({
		newPassword: yup.string().max(250).required(t('register.new-password-required')),
		confirmPassword: yup.string().max(250).required(t('register.confirm-password-required')),
		firstName: yup.string().max(250).required(t('register.first-name-required')),
		lastName: yup.string().max(250).required(t('register.last-name-required')),
	});
