import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formFieldSpacer, innerTitleSpacer, Tr } from '@utils';
import { Grid, Stack, SxProps, Typography } from '@mui/material';
import { FormikApiType, FormikTextfield, GenericCard } from '@components/common';
import { AddSubstanceOverlay } from '@components/common/AddSubstance';
import { useParams } from 'react-router-dom';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';

interface IrReferenceSubstanceDetailsProps {
	userReferenceData?: any;
	formik: FormikApiType;
	sx?: SxProps;
	editing?: boolean;
	substanceAdded?: () => void;
}

export const IrReferenceSubstanceDetails: FC<IrReferenceSubstanceDetailsProps> = ({
	userReferenceData,
	formik,
	sx,
	editing = false,
	substanceAdded: loadSubstance,
}) => {
	const { t } = useTranslation('irportal');
	const { status } = useParams<{ status: string }>();
	const disableRmInActiveFields = status === UserReferenceStatus.Inactive;

	useEffect(() => {
		if (editing) {
			formik.validateForm();
		}
	}, [userReferenceData]);

	return (
		<GenericCard sx={sx}>
			<Stack direction="row" justifyContent="space-between">
				<Stack marginBottom={innerTitleSpacer}>
					<Typography variant="h3" color={disableRmInActiveFields ? 'grey.400' : 'primary.main'}>
						<Tr.IrPortal path="drm-detail.substance-details" />
					</Typography>
				</Stack>
				<AddSubstanceOverlay
					isUserReferenceType
					referenceFormik={formik}
					disabled={disableRmInActiveFields}
					loadSubstance={loadSubstance}
				/>
			</Stack>
			<Grid container spacing={formFieldSpacer}>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="casNumber"
						title={t('drm-detail.cas-number')}
						placeholder={t('user-references.placeholders.cas-number')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="linearFormula"
						title={t('drm-detail.linear-formula')}
						placeholder={t('user-references.placeholders.molecular-formula')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="molecularWeight"
						title={t('drm-detail.molecular-weight')}
						placeholder={t('user-references.placeholders.molecular-weight')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="synonyms"
						title={t('drm-detail.synonyms')}
						placeholder={t('user-references.placeholders.synonyms')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="smilesCode"
						title={t('drm-detail.smiles')}
						placeholder={t('user-references.placeholders.smiles')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
			</Grid>
		</GenericCard>
	);
};
