import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PortalPageRoutes } from '@models';
import { RxUtils, analyticsAnalysisEvent } from '@utils';
import { irAnalysisService } from '@services';
import { IrConfirmAnalysis, IrRejectAnalysis } from '@components/ir-portal';
import { userSelector } from '@store/slices/common/common.slice';
import { IrAnalysisResultContext } from './Iranalysis-result-context';

interface IAnalysisConfirmation {
	confirmVisible: boolean;
	setConfirmationVisibility: (val: boolean) => void;
	rejectVisible: boolean;
	setRejectionVisibility: (val: boolean) => void;
	selectedRadioButton: number;
	setSelectedRMIndex: (val: number) => void;
}

export const AnalysisConfirmation: FC<IAnalysisConfirmation> = ({
	confirmVisible,
	setConfirmationVisibility,
	rejectVisible,
	setRejectionVisibility,
	selectedRadioButton,
	setSelectedRMIndex,
}) => {
	const user = useSelector(userSelector);
	const { analysisId, analysisResultData, triggerResult } = useContext(IrAnalysisResultContext);
	const navigate = useNavigate();

	if (!analysisId || !analysisResultData) return null;

	const sendReview = (decision: boolean, comment?: string, analysisError?: boolean, rank?: number) => {
		setSelectedRMIndex(0);
		RxUtils.promisify(irAnalysisService.sendReview(analysisId, decision, comment, rank), undefined, undefined, () => {
			if (!analysisResultData?.isConfirmedByLabMember()) {
				decision
					? analyticsAnalysisEvent.ApprovedLabMember(`${analysisId}`)
					: analyticsAnalysisEvent.RejectedLabMember(`${analysisId} ${analysisError ? 'analysis has wrong interpretation' : ''}`);
			} else {
				decision
					? analyticsAnalysisEvent.ApprovedLabAdmin(`${analysisId}`)
					: analyticsAnalysisEvent.RejectedLabAdmin(`${analysisId} ${analysisError ? 'analysis has wrong interpretation' : ''}`);
			}

			setConfirmationVisibility(false);
			setRejectionVisibility(false);
			//TODO SECOND APPROVAL
			if (!analysisResultData?.isConfirmedByLabMember() && analysisResultData?.irAnalysis?.secondApproval) {
				navigate(`/${PortalPageRoutes.DASHBOARD}`);
			} else {
				triggerResult();
			}
		});
	};

	return (
		<>
			<IrConfirmAnalysis
				visible={confirmVisible}
				user={user}
				onCancel={() => setConfirmationVisibility(false)}
				onConfirmation={(comment) => {
					sendReview(true, comment, false, selectedRadioButton + 1);
				}}
			/>

			<IrRejectAnalysis
				visible={rejectVisible}
				analysisId={analysisId}
				onCancel={() => setRejectionVisibility(false)}
				onReject={sendReview}
			/>
		</>
	);
};
