import { FormikApiType } from '@components/common';
import { useFilterSearchParams, useService } from '@hooks/common';
import { useIrAnalysis } from '@hooks/ir';
import { IrAnalysisType } from '@models/analysis';
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { alertService } from '@services/core';
import { IrLibrary, irLibraryService, IrSubstance } from '@services/ir';
import { Tr } from '@utils/Translation';
import { FC, useEffect } from 'react';

type TIdentificationMultiSelect = {
	libraries: IrLibrary[];
	formik: FormikApiType;
};

const IdentificationMultiSelect: FC<TIdentificationMultiSelect> = ({ libraries, formik }) => {
	const { isAnalysisEditable, updateSelectedLibrary } = useIrAnalysis();
	let formikSelectedLibrary = formik?.values?.selectedLibrary || [];

	const isAllItemsSelected = libraries?.every((l) => formikSelectedLibrary.includes(l.libraryId));
	const onSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const currentSelectedLibrary = (event.target as HTMLInputElement).value;

		if (event.target.checked) {
			updateSelectedLibrary([...formikSelectedLibrary, currentSelectedLibrary]);
		} else {
			formikSelectedLibrary = formikSelectedLibrary?.filter((libraryId: number) => libraryId !== +currentSelectedLibrary);
			updateSelectedLibrary([...formikSelectedLibrary]);
		}
	};

	const onAllItemsClicked = () => {
		if (isAllItemsSelected) {
			updateSelectedLibrary([]);
		} else {
			updateSelectedLibrary([...libraries.map((l) => l.libraryId.toString())]);
		}
	};

	const FormItem: FC<{ item: IrLibrary }> = ({ item }) => {
		return (
			<FormControlLabel
				value={item.libraryId}
				checked={formikSelectedLibrary?.includes(item.libraryId)}
				disabled={!isAnalysisEditable}
				sx={{ alignItems: 'center', ml: 0 }}
				control={
					<Checkbox
						name={item.libraryName}
						onChange={onSelectionChange}
						sx={{ width: 20, height: 20, '& .MuiSvgIcon-root': { fontSize: 20 } }}
						id={item.libraryCode}
					/>
				}
				label={
					<Typography variant="pg-m" sx={{ pl: 1.5 }}>
						<Tr.IrPortal
							path="new-analysis.library-name-spectra"
							options={{
								libraryName: item.libraryName,
								count: item.count ?? 0,
							}}
						/>
					</Typography>
				}
			/>
		);
	};

	const AllCheckboxItem: FC = () => {
		return (
			<FormControlLabel
				checked={isAllItemsSelected}
				disabled={!isAnalysisEditable}
				sx={{ alignItems: 'center', ml: 0 }}
				control={
					<Checkbox
						name={'All'}
						onChange={onAllItemsClicked}
						sx={{ width: 20, height: 20, '& .MuiSvgIcon-root': { fontSize: 20 } }}
					/>
				}
				label={
					<Typography variant="pg-m" sx={{ pl: 1.5 }}>
						<Tr.Common path="all" />
					</Typography>
				}
			/>
		);
	};

	const librariesFirstHalf = libraries.slice(0, libraries.length / 2);
	const librariesSecondHalf = libraries.slice(libraries.length / 2);
	return (
		<FormControl sx={{ marginBottom: 2 }} component="fieldset" variant="standard">
			<Stack direction="row">
				<Stack direction="column" width="50%" spacing={2}>
					{libraries.length > 0 && <AllCheckboxItem />}

					{librariesFirstHalf && librariesFirstHalf.map((item) => <FormItem key={item.libraryId} item={item} />)}
				</Stack>
				<Stack direction="column" width="50%" spacing={2}>
					{librariesSecondHalf && librariesSecondHalf.map((item) => <FormItem key={item.libraryId} item={item} />)}
				</Stack>
			</Stack>
		</FormControl>
	);
};

type TAnalysisLibraryRadioSelect = {
	libraries: IrLibrary[];
	formik: FormikApiType;
	displayedAddedSubstanceList: IrSubstance[];
};
const AnalysisLibraryRadioSelect: FC<TAnalysisLibraryRadioSelect> = ({ libraries, formik, displayedAddedSubstanceList }) => {
	const { updateSelectedLibrary, selectedLibrary } = useIrAnalysis();
	const { setSearchParams, searchParams } = useFilterSearchParams();

	useEffect(() => {
		const selectedLibraryObj = libraries.find((l) => l.libraryId.toString() === selectedLibrary?.[0]?.toString());
		if (selectedLibraryObj && selectedLibraryObj.isUserLibrary) {
			searchParams.set('IsUserLibrary', 'true');
			setSearchParams(searchParams);
		} else {
			searchParams.delete('IsUserLibrary');
			setSearchParams(searchParams);
		}
	}, [selectedLibrary]);
	const FormControlItem: FC<{ item: IrLibrary }> = ({ item }) => {
		return (
			<FormControlLabel
				value={item.libraryId}
				control={
					<Radio
						sx={{
							'& .MuiSvgIcon-root': {
								fontSize: 20,
							},
						}}
					/>
				}
				key={item.count}
				label={
					<Typography variant="pg-m" sx={{ pl: 0.4 }}>
						<Tr.IrPortal
							path="new-analysis.library-name-spectra"
							options={{
								libraryName: item.libraryName,
								count: item.count ?? 0,
							}}
						/>
					</Typography>
				}
			/>
		);
	};

	const onSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		displayedAddedSubstanceList?.length &&
			alertService.send({
				content: <Tr.IrPortal path="search-references.remove-drm-error" />,
				titleText: <Tr.IrPortal path="library-change" />,
				closeTextComponent: <Tr.IrPortal path="cancel" />,
				confirmTextComponent: <Tr.IrPortal path="search-references.continue" />,
				onConfirm: () => {
					displayedAddedSubstanceList?.length && displayedAddedSubstanceList.splice(0, displayedAddedSubstanceList.length);
					updateSelectedLibrary([(event.target as HTMLInputElement).value]);
				},
				onClose: () => undefined,
			});
		!displayedAddedSubstanceList.length && updateSelectedLibrary([(event.target as HTMLInputElement).value]);
	};
	const librariesFirstHalf = libraries.slice(0, libraries.length / 2 + 1);
	const librariesSecondHalf = libraries.slice(libraries.length / 2 + 1);
	return (
		<RadioGroup
			aria-labelledby="demo-radio-buttons-group-label"
			value={formik.values.selectedLibrary || []}
			name="selectedLibrary"
			onChange={onSelectionChange}
		>
			<Stack direction="row">
				<Stack width="50%" direction="column">
					{librariesFirstHalf && librariesFirstHalf.map((item) => <FormControlItem key={item.libraryId + 'radio'} item={item} />)}
				</Stack>
				<Stack width="50%" direction="column">
					{librariesSecondHalf &&
						librariesSecondHalf.map((item) => <FormControlItem key={item.libraryId + 'radio'} item={item} />)}
				</Stack>
			</Stack>
		</RadioGroup>
	);
};

type TIrAnalysisLibrarySelect = {
	formik: FormikApiType;
	displayedAddedSubstanceList: IrSubstance[];
};
const MAX_LIBRARY_COUNT = '999';
export const IrAnalysisLibrarySelect: FC<TIrAnalysisLibrarySelect> = ({ formik, displayedAddedSubstanceList }) => {
	const { isAnalysisEditable } = useIrAnalysis();

	const { data: libraries } = useService(() => {
		const searchParams = new URLSearchParams();
		searchParams.set('pageIndex', '1');
		searchParams.set('pageSize', MAX_LIBRARY_COUNT);
		searchParams.set('sortBy', 'createTime');
		searchParams.set('sortOrder', 'desc');
		return irLibraryService.get(searchParams);
	}, [formik?.values?.type]);

	const isIdentification = formik.values.type === IrAnalysisType.IDENTIFICATION;

	return (
		<FormControl sx={{ marginBottom: 2 }} component="fieldset" variant="standard" disabled={!isAnalysisEditable}>
			<Stack direction="row" alignItems="center" columnGap={2} mb={1}>
				<Typography variant="h4" color="grey.800">
					<Tr.IrPortal path={`new-analysis.analysis-library-${formik.values.type.toLowerCase()}-title`} />
				</Typography>
			</Stack>
			<Stack>
				{isIdentification ? (
					<IdentificationMultiSelect libraries={libraries?.data ?? []} formik={formik} />
				) : (
					<AnalysisLibraryRadioSelect
						libraries={libraries?.data ?? []}
						formik={formik}
						displayedAddedSubstanceList={displayedAddedSubstanceList}
					/>
				)}
			</Stack>
		</FormControl>
	);
};
