import { FC, ReactNode } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as FrameInspect } from '@material-symbols/svg-600/outlined/frame_inspect.svg';
import OpenWithOutlined from '@mui/icons-material/OpenWithOutlined';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlined from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import FitScreenOutlined from '@mui/icons-material/FitScreenOutlined';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import CameraAlt from '@mui/icons-material/CameraAlt';

const ChartIconButton: FC<{ icon: ReactNode; targetElement: string; chartClass: string; dataTestId?: string }> = ({
	icon,
	targetElement,
	chartClass,
	dataTestId,
}) => (
	<IconButton
		data-testid={dataTestId}
		onClick={() => {
			(document.querySelectorAll(`.${chartClass}  a[data-title="${targetElement}"]`) as unknown as any[]).forEach((item) =>
				item.click(),
			);
		}}
	>
		{icon}
	</IconButton>
);

interface IChartHeaderProps {
	titleVisibility?: boolean;
	title?: ReactNode;
	chartClass: string;
	showModeBar?: boolean;
}

export const NmrChartHeader: FC<IChartHeaderProps> = ({ chartClass, title, titleVisibility, showModeBar = true }) => {
	return (
		<Stack data-testid="chart-header-test-id" direction="row" justifyContent="space-between" marginX="1rem" alignItems="center">
			{titleVisibility && (
				<Typography sx={{ color: 'text.primary', margin: '24px', fontSize: '1.1rem' }} fontWeight={700}>
					{title}
				</Typography>
			)}
			{showModeBar && (
				<Box marginLeft="auto">
					<ChartIconButton
						dataTestId="modal-zoom-id"
						chartClass={chartClass}
						targetElement="Zoom"
						icon={<FrameInspect width={24} height={24} fill="#5E5E72" />}
					/>
					<ChartIconButton
						dataTestId="download-plot"
						chartClass={chartClass}
						targetElement="Download plot as a png"
						icon={<CameraAlt />}
					/>
					<ChartIconButton dataTestId="modal-pan-id" chartClass={chartClass} targetElement="Pan" icon={<OpenWithOutlined />} />
					<ChartIconButton
						dataTestId="modal-zoom-in-id"
						chartClass={chartClass}
						targetElement="Zoom in"
						icon={<AddBoxOutlined />}
					/>
					<ChartIconButton
						dataTestId="modal-zoom-out-id"
						chartClass={chartClass}
						targetElement="Zoom out"
						icon={<IndeterminateCheckBoxOutlined />}
					/>
					<ChartIconButton
						dataTestId="modal-autoscale-id"
						chartClass={chartClass}
						targetElement="Autoscale"
						icon={<FitScreenOutlined />}
					/>
					<ChartIconButton
						dataTestId="modal-reset-axes-id"
						chartClass={chartClass}
						targetElement="Reset axes"
						icon={<HomeOutlined />}
					/>
				</Box>
			)}
		</Stack>
	);
};
