import React, { useEffect, useState, useMemo } from 'react';
import { QualityGrade } from '@models/reference-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import {
	DeactivationConfirmPopUp,
	GenericCard,
	MolecularFormula,
	OverflowText,
	PortalBadge,
	QualityGradeChip,
	ViewMore,
} from '@components/common';
import { alertService, notificationService, irDrmService, IrDrmDetail } from '@services';
import { DateUtils, RxUtils, Tr } from '@utils';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '@store/slices/common/common.slice';
import { differenceInWeeks } from 'date-fns';
import { SubstanceTechnology } from '@components/admin';
import { TechnologyPermissionKeys } from '@models/user';
import { useRestrictedComponent } from '@hooks';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { IrDrmSpectrumChart } from '@components/common/IrDrmSpectrumChart/IrDrmSpectrumChart';
import { IDrmSpectrumDataType } from '@components/ir-portal';

import { DrmDivider, DrmRow, DrmSubtitle } from '@components/admin/DrmDetailComponents';
import { getReferenceBorderColor } from '@utils/QualityGrade';

export const EditIrDrm = () => {
	const { id } = useParams();
	const { t } = useTranslation('admin');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const media = useMediaQuery((theme: Theme) => theme.breakpoints.down(1350));

	const [confirmation, setConfirmation] = useState<{ visible: Maybe<boolean> }>({ visible: null });
	const [drmFile, setDrmFile] = useState<IDrmSpectrumDataType>();
	const [drm, setDrm] = useState<IrDrmDetail>();
	const tech = t('drm');
	const { isActive } = drm ?? { isActive: true };

	const changeErmStatus = (msg: string) => {
		id &&
			RxUtils.promisify(
				irDrmService.updateErmActivity(+id, msg, !isActive),
				() => (
					setConfirmation({ visible: false }),
					notificationService.sendSuccess(
						isActive
							? t('substance-detail.deactivated-successfully', { tech: tech, ermName: drm?.name })
							: t('substance-detail.activated-successfully', { tech: tech, ermName: drm?.name }),
					),
					navigate(-1)
				),
			);
	};

	useEffect(() => {
		if (id) {
			RxUtils.promisify(irDrmService.get(+id), (drmDetail) => {
				dispatch(
					setBreadcrumb({
						substanceName: drmDetail?.substanceName,
						productNumber: drmDetail?.drmCode ? drmDetail?.drmCode : t('substance-detail.drm-details'),
						':id': drmDetail?.substanceId,
					}),
				);

				setDrm(drmDetail);
			});
		}
	}, [id]);

	useEffect(() => {
		if (drm && id) {
			RxUtils.promisify(irDrmService.getERMFile(+id), (data) => {
				const xAry: number[] = [];
				for (let i = data['x'].start; i >= data['x'].stop; i--) {
					xAry.push(i);
				}
				const spectrumInfo: IDrmSpectrumDataType = {
					id: drm.drmCode,
					title: drm.substanceName,
					x: xAry,
					y: data?.['y'],
					firstx: data['x'].start,
					lastx: data['x'].stop,
				};
				setDrmFile(spectrumInfo);
			});
		}
	}, [drm]);

	const isNew = useMemo(() => (drm?.createTime ? Math.abs(differenceInWeeks(new Date(drm?.createTime), new Date())) < 1 : false), [drm]);

	const SubstanceDetails = () => {
		return (
			<>
				<DrmSubtitle isActive={drm?.isActive}>
					<Tr.Admin path="substance-detail.substance-details" />
				</DrmSubtitle>
				<DrmRow label={t('substance-detail.cas-number')} result={drm?.casNumber} isActive={drm?.isActive} />
				<DrmRow label={t('substance-detail.molecular-formula')} result={drm?.linearFormula} isActive={drm?.isActive} />
				<DrmRow
					label={t('substance-detail.molecular-weight')}
					result={`${drm?.molecularWeight} ${t('substance-detail.molecular-weight-unit')}`}
					isActive={drm?.isActive}
				/>
				<DrmRow label={t('substance-detail.smiles')} result={drm?.smilesCode} isActive={drm?.isActive} />
			</>
		);
	};

	const PhysicalProductDetails = () => {
		return (
			<>
				<DrmSubtitle isActive={drm?.isActive}>
					<Tr.Admin path="substance-detail.physical-product-details" />
				</DrmSubtitle>
				<DrmRow
					label={t('substance-detail.physical-product-number')}
					result={drm?.physicalProductNumber}
					isActive={drm?.isActive}
				/>
				<DrmRow
					label={t('substance-detail.physical-product-quality')}
					result={drm?.physicalProductQuality}
					isActive={drm?.isActive}
				/>
				<DrmRow
					label={t('substance-detail.physical-product-batch-number')}
					result={drm?.physicalProductBatchNumber}
					isActive={drm?.isActive}
				/>
			</>
		);
	};

	const AcquisitionParameters = () => {
		return (
			<>
				<DrmSubtitle isActive={drm?.isActive}>
					<Tr.Admin path="substance-detail.acquisition-parameters" />
				</DrmSubtitle>
				<DrmRow label={t('substance-detail.sampling-technique')} result={drm?.samplingTechnique} isActive={drm?.isActive} />
				<DrmRow label={t('substance-detail.number-of-scans')} result={drm?.numberOfScans} isActive={drm?.isActive} />
				<DrmRow label={t('substance-detail.resolution')} result={drm?.resolution} unit=" cm⁻¹" isActive={drm?.isActive} />
				<DrmRow
					label={t('substance-detail.lower-range')}
					result={drm?.lastX?.split('.')[0]}
					unit=" cm⁻¹"
					isActive={drm?.isActive}
				/>
				<DrmRow
					label={t('substance-detail.upper-range')}
					result={drm?.firstX?.split('.')[0]}
					unit=" cm⁻¹"
					isActive={drm?.isActive}
				/>
			</>
		);
	};

	const AdditionalInformation = () => {
		return (
			<>
				<DrmSubtitle isActive={drm?.isActive}>
					<Tr.Admin path="substance-detail.additional-information" />
				</DrmSubtitle>

				<DrmRow label={t('substance-detail.reference-code')} result={drm?.drmCode} isActive={drm?.isActive} />
				<DrmRow label={t('substance-detail.reference-version')} result={drm?.version} isActive={drm?.isActive} />
				<DrmRow
					label={t('substance-detail.release-date')}
					result={drm?.createTime ? DateUtils.getFormattedDate(drm.createTime, 'dd/MM/yyyy') : '-'}
					isActive={drm?.isActive}
				/>
				<Grid item>
					<Grid container alignItems="start" columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 16 }}>
						<Grid item xs={12} sm={8} md={6} lg={5} xl={5}>
							<OverflowText variant="pg-m" width="100%" color={!drm || drm?.isActive ? 'text.primary' : 'grey.500'}>
								<Tr.Admin path="substance-detail.synonyms" />
							</OverflowText>
						</Grid>
						<Grid item xs={12} sm={4} md={6} lg={7} xl={11}>
							<ViewMore
								content={drm?.synonyms || '-'}
								typographyProps={{
									variant: 'pg-m',
									sx: {
										color: !drm || drm?.isActive ? 'grey.800' : 'grey.500',
									},
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	};

	const IrgradeTypes = Object.keys(QualityGrade);

	const borderColor = getReferenceBorderColor(drm?.qualityGrade, !isActive);

	const molecularFormulaRef = React.createRef<HTMLDivElement>();
	return (
		<>
			<GenericCard dataTestId="generic-card-id" containerSx={{ flexGrow: 1 }}>
				<Grid container spacing={7}>
					<Grid item md={4} xl={4}>
						<MolecularFormula
							height={media ? 150 : 280}
							sx={{ width: '100%', height: '282px' }}
							deactivateColors={!isActive}
							smilesFormula={drm?.smilesCode}
							ref={molecularFormulaRef}
							borderColor={borderColor}
						/>
						<Box
							position="relative"
							sx={{
								borderWidth: 2,
								borderStyle: 'solid',
								borderColor: isActive ? (borderColor ? borderColor : 'primary.main') : 'grey.800',
								minWidth: '10rem',
								width: '100%',
								borderRadius: 2,
								height: '282px',
								mt: 4,
							}}
						>
							{drmFile && (
								<IrDrmSpectrumChart
									sx={{ padding: 0 }}
									chartData={drmFile}
									hideZoomIcon={false}
									uniqueId="edit-erm-chart"
									axisTitle="Wavenumber [cm⁻¹]"
									layout={{ margin: { pad: 0, t: 30, l: 60, r: 15, b: 50 }, height: 254 }}
									isDeactivated={drm && !drm?.isActive}
									borderColor={borderColor}
								/>
							)}
						</Box>
					</Grid>
					<Grid item sx={{ width: '100%' }} xs={12} md={8} xl={8}>
						<Stack width="100%">
							<Grid container direction="column" spacing={1.5} flexWrap="nowrap">
								<Grid item xs={12}>
									<Stack direction="row" gap={1.5}>
										{drm && IrgradeTypes.includes(drm.qualityGrade.toUpperCase()) && (
											<QualityGradeChip isReferenceActive={drm?.isActive || false} qualityGrade={drm?.qualityGrade} />
										)}
										<SubstanceTechnology.IrReferenceMaterial disabled={!isActive} qualityGrade={drm?.qualityGrade} />
										{isNew && (
											<PortalBadge
												badgeContent={
													<Typography
														color={!drm || drm?.isActive ? 'text.primary' : 'grey.500'}
														variant="subtitle3"
														lineHeight="12px"
													>
														<Tr.Common path="new" />
													</Typography>
												}
												sx={{
													alignItems: 'center',
													'& .MuiBadge-badge': {
														backgroundColor: !drm || drm?.isActive ? 'warning.main' : 'grey.100',
													},
												}}
											/>
										)}
									</Stack>
									<Stack marginTop={2} mb={0.5}>
										<Typography variant="h3" color={isActive ? 'text.primary' : 'grey.500'}>
											{drm?.name}
										</Typography>
									</Stack>
								</Grid>
								<SubstanceDetails />
								<DrmDivider />
								<PhysicalProductDetails />
								<DrmDivider />
								<AcquisitionParameters />
								<DrmDivider />
								<AdditionalInformation />
							</Grid>
						</Stack>
					</Grid>
				</Grid>
			</GenericCard>

			{useRestrictedComponent(
				TechnologyPermissionKeys.EDIT_DRM,
				<Button
					color={isActive ? 'error' : 'primary'}
					sx={{
						width: '5rem',
						marginTop: 2,
					}}
					size="medium"
					variant="text"
					disableElevation
					data-testid="deactivate-erm-button-id"
					onClick={() => {
						if (!isActive) {
							alertService.send({
								titleText: <Tr.Admin path="substance-detail.confirmation" />,
								content: <Tr.Admin path="substance-detail.reactivation-message" options={{ tech: tech }} />,
								closeTextComponent: <Tr.Common path="cancel" />,
								confirmTextComponent: <Tr.Admin path="substance-detail.reactivate" />,
								confirmTextHeader: <Tr.Admin path="substance-detail.reactivation-subtitle" options={{ tech: tech }} />,
								onConfirm: () => changeErmStatus(''),
								onClose: () => null,
							});
						} else {
							setConfirmation({ visible: true });
						}
					}}
				>
					<Tr.Admin path={isActive ? 'substance-detail.deactivate' : 'substance-detail.reactivate'} />
				</Button>,
				SupportedTechnology.IR,
			)}

			<DeactivationConfirmPopUp
				visible={!!confirmation.visible}
				message={t('substance-detail.deactivation-reason-placeholder', { tech: tech })}
				title={<Tr.Admin path="substance-detail.confirmation" />}
				subTitle={
					<>
						<Stack>
							<Typography variant="subtitle1">
								<Tr.Admin path="substance-detail.deactivation-subtitle" options={{ tech: tech }} />
							</Typography>
						</Stack>
						<Stack>
							<Typography variant="pg-m">
								<Tr.Admin path="substance-detail.deactivation-message" options={{ tech: tech }} />
							</Typography>
						</Stack>
					</>
				}
				onConfirm={(msg) => changeErmStatus(msg)}
				onCancel={() => setConfirmation({ visible: null })}
				buttonText={<Tr.Admin path="substance-detail.deactivate" />}
			/>
		</>
	);
};
