import { GenericCard, RequestErmListItem } from '@components/common';
import { Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { FC, useEffect } from 'react';
import { QuantitiveNonTargetedPeaksInitialize } from './QuantitiveNonTargetedPeaksInitialize';
import { useService } from '@hooks';
import { nmrAnalysisService } from '@services/nmr';
import { useParams } from 'react-router-dom';
import { useAnalysis } from '@hooks';
import { config } from '@config';
import { HubConnection } from '@microsoft/signalr';
import { QuantitativeNonTargetedIntegrationHubMethods } from '@models';
import { useSignalRHubConnection } from '@hooks';
import { SelectableIntegratedPeakTable } from './SelectableIntegratedPeakTable';
import { getIsQuantitiveNonTargeted } from '@models';
import { QuantitiveNonTargetedTechnicalError } from './QuantitiveNonTargetedTechnicalError';

export const QuantitiveNonTargetedProcedure: FC = () => {
	const { id = '' } = useParams<{ id: string }>();
	const { measurementType, targetType, updateChangedPeaks } = useAnalysis();

	const isQuantitiveNonTargeted = getIsQuantitiveNonTargeted(measurementType, targetType);
	const { data: peakTableData, trigger: triggerQuantitiveSpectrumGet } = useService(() => {
		return nmrAnalysisService.getQuantitativeSpectrumData(+id);
	}, [isQuantitiveNonTargeted]);

	useSignalRHubConnection({
		hubUrl: config.quantitativeNonTargetedIntegrationUrl,
		body: (connection: HubConnection) => {
			connection.send(QuantitativeNonTargetedIntegrationHubMethods.CHECK_INTEGRATION_STATUS, +id);
			connection.on(QuantitativeNonTargetedIntegrationHubMethods.INTEGRATION_COMPLETED, () => {
				triggerQuantitiveSpectrumGet();
			});
		},
		shouldStartConnection: () => peakTableData?.isExecuting(),
		deps: [peakTableData],
	});

	const getCurrentStateNode = () => {
		if (!peakTableData || peakTableData.isExecuting()) {
			return <QuantitiveNonTargetedPeaksInitialize />;
		}
		if (peakTableData.isTechnicalError()) {
			return <QuantitiveNonTargetedTechnicalError />;
		}
		return (
			<SelectableIntegratedPeakTable
				peakData={peakTableData.peaks}
				refresh={() => {
					triggerQuantitiveSpectrumGet();
				}}
			/>
		);
	};
	useEffect(() => {
		updateChangedPeaks(peakTableData?.peaks.filter((value) => value.isSelected));
	}, [peakTableData]);
	return (
		<Stack>
			<GenericCard sx={{ marginTop: 4 }}>
				<Typography variant="h3" color="primary.main">
					<Tr.Portal path="new-analysis.step-2-integrated-peak-table" />
				</Typography>

				{getCurrentStateNode()}
			</GenericCard>
			<RequestErmListItem title={<Tr.Portal path="new-analysis.request-a-drm" />} />
		</Stack>
	);
};
