/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { HubConnection } from '@microsoft/signalr';

import { useService, useSignalRHubConnection } from '@hooks';
import {
	IrAnalysisStage,
	CombinedPeakFeatures,
	fileService,
	irAnalysisService,
	RankBasedMixtureData,
	SampleSpectrumInfo,
	SpectrumMetaData,
	SampleSpectrumInfoPurity,
	RmSpectrumInfoPurity,
	IrReferenceMaterialResult,
	IrPurityCheckPeakjson,
	IrImpurityPeak,
} from '@services';
import { config } from '@config';
import { AnalysisInProgressModal } from '@components/common';
import { UpdateButtons } from './UpdateButtons';
import { IrAnalysisResultFields } from './IrAnalysisResultFields';
import { AnalysisMatchTable } from './IrAnalysisMatchTable';
import { IrAnalysisResultContext } from './Iranalysis-result-context';
import { IrAlgorithmSummary, IrAnalysisSetupSummary, LogoFooter, IrAnalysisResultLabel } from '@components/ir-portal';
import { AnalysisErrorSummary } from './IrAnalysisErrorSummary';
import { AnalysisHubMethods } from '@models';
import { setBreadcrumb } from '@store/slices/common/common.slice';
import { AnalysisMatchComparison } from './IrAnalysisMatchComparison';
import { RxUtils } from '@utils/Rx';
import { IrAnalysisType } from '@models';

export const IrAnalysisResult: FC = () => {
	const { id = '' } = useParams<{ id: string }>();

	const { data: analysisResultData, trigger: refreshResult } = useService(() => irAnalysisService.getResult(+id), [id]);
	const matchedResult = analysisResultData?.resultMatches;
	let resultImpurities = analysisResultData?.resultImpurities || [];
	const rankMixtureAry: RankBasedMixtureData[] = [];
	const [rankBasedMixtureData, setRankBasedMixtureData] = useState<RankBasedMixtureData[]>([]);
	const [rmData, setRmData] = useState<any>();
	const [sampleData, setSampleData] = useState<any>();
	const [resultTableradioSelectedbutton, setResultTableRadioSelectedbutton] = useState<number>(0);
	const [userSpectrumInfo, setUserSpectrumInfo] = useState<SampleSpectrumInfo>({
		peak_features: { peak_wavenumbers: [], peak_heights: [] },
		sample_x: [],
		sample_y: [],
		sample_x_range: [],
		baseline_curve_y: [],
		sampleName: '',
		sampleSpectrumUrl: '',
		metadata: { title: '', data_type: '', sampling_procedure: '', xunits: '', yunits: '', resolution: 0 },
	});

	const [userSpectrumInfoPurityCheck, setUserSpectrumInfoPurityCheck] = useState<SampleSpectrumInfoPurity>({
		sample_x: [],
		sample_y: [],
		sample_x_range: [],
	});

	const [rmSpectrumInfoPurityCheck, setRmSpectrumInfoPurityCheck] = useState<RmSpectrumInfoPurity>({
		spectrum_x: [],
		spectrum_y: [],
		x_range: [],
	});
	const [selectedRMIndex, setSelectedRMIndex] = useState<number>(0);
	const [peakData, setPeakData] = useState<IrPurityCheckPeakjson[]>();
	const [impurityPeakData, setImpurityPeakData] = useState<IrImpurityPeak[]>();

	const dispatch = useDispatch();

	useEffect(() => {
		document.querySelector('#layout-grid') && (document.querySelector('#layout-grid')!.scrollTop = 0);
	}, []);

	useEffect(() => {
		dispatch(
			setBreadcrumb({
				stage: analysisResultData?.irAnalysis?.stage,
				title: analysisResultData?.irAnalysis?.title,
				isCompleted: analysisResultData?.irAnalysis?.isCompleted,
			}),
		);
	}, [analysisResultData]);

	useEffect(() => {
		{
			analysisResultData?.rmSpectrum &&
				//get RMData
				RxUtils.promisify(fileService.getFileFromUrl(analysisResultData?.rmSpectrum || ''), (data) => {
					setRmData(data);
				});
		}
		{
			analysisResultData?.sampleSpectrum &&
				RxUtils.promisify(fileService.getFileFromUrl(analysisResultData?.sampleSpectrum || ''), (data) => {
					setSampleData(data);
				});
		}

		if (analysisResultData?.resultImpurities) {
			const analysisResultImpurities = analysisResultData.resultImpurities;
			analysisResultImpurities.map((impurity) => {
				RxUtils.promisify(fileService.getFileFromUrl(impurity?.impuritySpectrum || ''), (data) => {
					Object.assign(impurity, { impuritySpectrumData: data['impurity_spectrum'] });
				});
				resultImpurities = [...analysisResultImpurities];
			});
		}

		if (analysisResultData?.impurityPeakJson) {
			RxUtils.promisify(fileService.getFileFromUrl(analysisResultData?.impurityPeakJson || ''), (data): any => {
				const impurityData = data['impurity_peak'];
				setImpurityPeakData(impurityData);
			});
		}

		if (analysisResultData?.peakJson) {
			RxUtils.promisify(fileService.getFileFromUrl(analysisResultData?.peakJson || ''), (data) => {
				const resultData = data['peack_json'];
				setPeakData(resultData);
			});
		}
	}, [analysisResultData, resultImpurities, selectedRMIndex]);

	useEffect(() => {
		if (analysisResultData?.irAnalysis?.type !== IrAnalysisType.IDENTIFICATION) {
			if (sampleData && sampleData?.['user_spectrum']) {
				const xAry: number[] = [];
				for (let i = sampleData?.['user_spectrum'].x.start; i >= sampleData?.['user_spectrum'].x.stop; i--) {
					xAry.push(i);
				}

				const userSpecData: SampleSpectrumInfoPurity = {
					sample_x: xAry,
					sample_x_range: [sampleData?.['user_spectrum'].x.start, sampleData?.['user_spectrum'].x.stop],
					sample_y: sampleData?.['user_spectrum'].y,
				};

				setUserSpectrumInfoPurityCheck(userSpecData);
			}
		} else {
			if (sampleData && sampleData?.['user_spectrum']) {
				const xAry: number[] = [];
				for (let i = sampleData?.['user_spectrum'].x.start; i >= sampleData?.['user_spectrum'].x.stop; i--) {
					xAry.push(i);
				}
				const metadata: SpectrumMetaData = {
					title: sampleData?.['user_spectrum']['metadata'].title,
					data_type: sampleData?.['user_spectrum']['metadata']['data type'],
					sampling_procedure: sampleData?.['user_spectrum']['metadata']['sampling procedure'],
					xunits: sampleData?.['user_spectrum']['metadata'].xunits,
					yunits: sampleData?.['user_spectrum']['metadata'].yunits,
					resolution: sampleData?.['user_spectrum']['metadata'].resolution,
				};

				const userSpecData: SampleSpectrumInfo = {
					sample_x: xAry,
					sample_x_range: [sampleData?.['user_spectrum'].x.start, sampleData?.['user_spectrum'].x.stop],
					sample_y: sampleData?.['user_spectrum'].y,
					baseline_curve_y: sampleData?.['baseline_correction_curve'],
					sampleName: sampleData?.['user_spectrum']['metadata'].title,
					sampleSpectrumUrl: analysisResultData?.sampleSpectrum,
					metadata: metadata,
					peak_features: sampleData?.['peak_features'],
				};

				setUserSpectrumInfo(userSpecData);
			}
		}
	}, [sampleData, analysisResultData]);

	useEffect(() => {
		if (analysisResultData?.irAnalysis?.type !== IrAnalysisType.IDENTIFICATION) {
			if (rmData && matchedResult) {
				for (let i = 0; i < matchedResult.length; i++) {
					const matchVar = matchedResult[`${i}`];
					let combined_features_info: CombinedPeakFeatures = { peak_wavenumbers: [], peak_heights: [] };
					let combined_spectrum_y = [];
					let spectrum_x: number[] = [];
					let matchFac = 0;
					let libName = '';
					const mixAry: IrReferenceMaterialResult[] = [];
					for (let j = 0; j < matchVar['irAnalysisResultMixtures'].length; j++) {
						const mixture = matchVar['irAnalysisResultMixtures'][`${j}`];
						if (mixture?.qualityGrade !== null) {
							if (
								rmData !== undefined &&
								rmData['match_results']?.['rank_' + (i + 1)]?.['mixture_' + mixture.position]?.['drmCode'] ===
									mixture?.['referenceMaterialId']
							) {
								mixture.scaled_spectrum_y =
									rmData['match_results']?.['rank_' + (i + 1)]?.['mixture_' + mixture.position]?.['scaled_spectrum_y'];
								const xAry: number[] = [];
								for (
									let k = rmData['match_results']?.['rank_' + (i + 1)]?.['spectrum_x'].start;
									k >= rmData['match_results']?.['rank_' + (i + 1)]?.['spectrum_x'].stop;
									k--
								) {
									xAry.push(k);
								}
								spectrum_x = xAry;
								combined_spectrum_y = rmData['match_results']?.['rank_' + (i + 1)]?.['combined_spectrum_y'];
								combined_features_info = rmData['match_results']?.['rank_' + (i + 1)]?.['combined_spectrum_peak_features'];
								matchFac = mixture.matchFactor;

								libName = mixture.library;
							}
							mixAry.push(mixture);
						}
					}
					rankMixtureAry.push({
						rank: matchVar?.['rank'],
						matchFactor: matchFac,
						selectedResult: matchVar?.['selectedResult'],
						drmCode: matchVar?.drmCode,
						library: libName,
						mixtures: mixAry,
						combined_spectrum_peak_features: combined_features_info,
						combined_spectrum_y: combined_spectrum_y,
						spectrum_x: spectrum_x,
						x_range: [spectrum_x[0], spectrum_x[spectrum_x.length - 1]],
					});
					const filteredAry = rankMixtureAry.filter(
						(item) => item.mixtures.filter((mixture) => mixture.qualityGrade !== null).length > 0 && !!item.rank,
					);
					setRankBasedMixtureData(filteredAry);
				}
				if (rmData?.['match_results']) {
					const xAry: number[] = [];
					for (let i = rmData?.['match_results'].x.start; i >= rmData?.['match_results'].x.stop; i--) {
						xAry.push(i);
					}

					const rmSpecData: RmSpectrumInfoPurity = {
						spectrum_x: xAry,
						x_range: [rmData?.['match_results'].x.start, rmData?.['match_results'].x.stop],
						spectrum_y: rmData?.['match_results'].y,
					};

					setRmSpectrumInfoPurityCheck(rmSpecData);
				}
			}
		} else {
			if (rmData && matchedResult) {
				for (let i = 0; i < matchedResult.length; i++) {
					const matchVar = matchedResult[`${i}`];
					const selectedRanked = matchVar['rank'];
					let combined_features_info: CombinedPeakFeatures = { peak_wavenumbers: [], peak_heights: [] };
					let combined_spectrum_y = [];
					let spectrum_x: number[] = [];
					let matchFac = 0;
					let libName = '';
					const mixAry: IrReferenceMaterialResult[] = [];
					let table_of_assignment: IrPurityCheckPeakjson = {};
					for (let j = 0; j < matchVar['irAnalysisResultMixtures'].length; j++) {
						const mixture = matchVar['irAnalysisResultMixtures'][`${j}`];
						if (mixture?.qualityGrade !== null) {
							if (
								rmData !== undefined &&
								rmData['match_results']?.['rank_' + (i + 1)]?.['mixture_' + mixture.position]?.['drmCode'] ===
									mixture?.['referenceMaterialId']
							) {
								mixture.scaled_spectrum_y =
									rmData['match_results']?.['rank_' + (i + 1)]?.['mixture_' + mixture.position]?.['scaled_spectrum_y'];
								const xAry: number[] = [];
								for (
									let k = rmData.match_results?.['rank_' + (i + 1)]?.['spectrum_x'].start;
									k >= rmData.match_results?.['rank_' + (i + 1)]?.['spectrum_x'].stop;
									k--
								) {
									xAry.push(k);
								}
								spectrum_x = xAry;
								combined_spectrum_y = rmData['match_results']?.['rank_' + (i + 1)]?.['combined_spectrum_y'];
								combined_features_info = rmData['match_results']?.['rank_' + (i + 1)]?.['combined_spectrum_peak_features'];
								table_of_assignment = rmData['match_results']?.['rank_' + (i + 1)]?.['table_of_assignment'];
								matchFac = mixture.matchFactor;

								libName = mixture.library;
							} else {
								mixture.scaled_spectrum_y =
									rmData['match_results']?.['rank_' + selectedRanked]?.['mixture_' + mixture.position]?.[
										'scaled_spectrum_y'
									];
								const xAry: number[] = [];
								for (
									let k = rmData['match_results']?.['rank_' + selectedRanked]?.['spectrum_x'].start;
									k >= rmData['match_results']?.['rank_' + selectedRanked]?.['spectrum_x'].stop;
									k--
								) {
									xAry.push(k);
								}
								spectrum_x = xAry;
								combined_spectrum_y = rmData['match_results']?.['rank_' + selectedRanked]?.['combined_spectrum_y'];
								combined_features_info =
									rmData['match_results']?.['rank_' + selectedRanked]?.['combined_spectrum_peak_features'];
								table_of_assignment = rmData['match_results']?.['rank_' + (i + 1)]?.['table_of_assignment'];
								matchFac = mixture.matchFactor;

								libName = mixture.library;
							}
							mixAry.push(mixture);
						}
					}
					rankMixtureAry.push({
						rank: matchedResult[`${i}`]?.['mixture'],
						matchFactor: matchFac,
						selectedResult: matchedResult[`${i}`]?.['selectedResult'],
						drmCode: matchVar.drmCode,
						library: libName,
						mixtures: mixAry,
						combined_spectrum_peak_features: combined_features_info,
						combined_spectrum_y: combined_spectrum_y,
						spectrum_x: spectrum_x,
						x_range: [spectrum_x[0], spectrum_x[spectrum_x.length - 1]],
						table_of_assignment: table_of_assignment,
					});
					console.log(matchedResult[`${i}`]);
					const filteredAry = rankMixtureAry.filter(
						(item) => item.mixtures.filter((mixture) => mixture.qualityGrade !== null).length > 0 && !!item.rank,
					);
					setRankBasedMixtureData(filteredAry);
				}
			}
		}
	}, [rmData, selectedRMIndex, analysisResultData]);
	useSignalRHubConnection({
		hubUrl: config.analysisHubUrl,
		body: (connection: HubConnection) => {
			connection.send(AnalysisHubMethods.CHECK_ANALYSIS_RESULT, +id);
			connection.on(AnalysisHubMethods.ANALYSIS_COMPLETED, (stage: IrAnalysisStage) => {
				if (stage !== IrAnalysisStage.EXECUTING) {
					refreshResult();
					document.querySelector('#layout-grid') && (document.querySelector('#layout-grid')!.scrollTop = 0);
				}
			});
		},
		shouldStartConnection: () => analysisResultData?.isExecuting(),
		deps: [analysisResultData],
	});

	const onRMTabChange = (newValue: number) => {
		setSelectedRMIndex(newValue);
	};
	const onChangeMatchTable = (value: number) => {
		setResultTableRadioSelectedbutton(value);
	};
	if (!analysisResultData) return null;

	return analysisResultData?.isExecuting() ? (
		<AnalysisInProgressModal />
	) : (
		<IrAnalysisResultContext.Provider
			value={{
				analysisResultData: analysisResultData,
				triggerResult: () => refreshResult(),
				analysisId: +id,
				peakData: peakData && peakData,
				impurityPeakData: impurityPeakData && impurityPeakData,
			}}
		>
			<Grid data-testid="result-grid-wrapper-id" container sx={{ maxWidth: 'auto', marginX: 'auto' }} rowGap={2}>
				<Grid item md={6} xs={6} sm={6}>
					<IrAnalysisResultLabel />
				</Grid>
				<Grid item md={6} xs={6} sm={6}>
					<UpdateButtons
						selectedRadioButton={resultTableradioSelectedbutton}
						userSpectrumInfo={userSpectrumInfo}
						rankedRMData={rankBasedMixtureData[`${selectedRMIndex}`]}
						userSpectrumInfoPurityCheck={userSpectrumInfoPurityCheck}
						rmSpectrumInfoPurityCheck={rmSpectrumInfoPurityCheck}
						resultImpurities={resultImpurities}
						setSelectedRMIndex={setSelectedRMIndex}
					/>
				</Grid>
				<Grid item md={12} xs={12} sm={12}>
					{id && <IrAnalysisResultFields id={+id} />}
				</Grid>

				<Grid item md={12} xs={12} sm={12}>
					<AnalysisErrorSummary />
				</Grid>
				<Grid item md={12} xs={12} sm={12}>
					<AnalysisMatchTable
						onChangeMatchTable={onChangeMatchTable}
						rankedRMData={rankBasedMixtureData}
						selectedRMIndex={selectedRMIndex}
						resultImpurities={resultImpurities}
					/>
				</Grid>
				<Grid item md={12} xs={12} sm={12}>
					<AnalysisMatchComparison
						rankedRMData={rankBasedMixtureData}
						userSpectrumInfo={userSpectrumInfo}
						userSpectrumInfoPurityCheck={userSpectrumInfoPurityCheck}
						rmSpectrumInfoPurityCheck={rmSpectrumInfoPurityCheck}
						setIndexOnTabChange={onRMTabChange}
						resultImpurities={resultImpurities}
						analysisResultSelectedOptionIndex={resultTableradioSelectedbutton}
						selectedRMIndex={selectedRMIndex}
					/>
				</Grid>
				<Grid item md={12} xs={12} sm={12}>
					<IrAnalysisSetupSummary rankedRMData={rankBasedMixtureData[`${selectedRMIndex}`]} />
				</Grid>
				<Grid item md={12} xs={12} sm={12}>
					<IrAlgorithmSummary type={analysisResultData.irAnalysis.type} />
				</Grid>
				<Grid item md={12} xs={12} sm={12}>
					<LogoFooter />
				</Grid>
			</Grid>
		</IrAnalysisResultContext.Provider>
	);
};
