import { useState } from 'react';
import { NmrDevice, NmrSubstance } from '@services';
import { AnalysisContext } from '.';
import { QuantitativeStep } from './props';
import { AnalysisTargetType, QualitativeOrQuantitative } from '@models/analysis';
import { MetaDataType } from '@hooks';
import { QuantitivePeakType } from '@services/nmr/nmr-analysis/nmr-quantitive-spectrum.class';
import { ExtendedComponent } from 'src/types';

export const AnalysisProvider: ExtendedComponent = ({ children }) => {
	const [quantitativeStep, setQuantitativeStep] = useState<Maybe<QuantitativeStep>>(QuantitativeStep.STEP_1);
	const [addedERMs, setAddedERMs] = useState<NmrSubstance[]>([]);
	const [nmrDevice, setNmrDevice] = useState<Maybe<NmrDevice>>(undefined);
	const [measurementType, setMeasurementType] = useState<Maybe<QualitativeOrQuantitative>>();
	const [metaData, setMetaData] = useState<Maybe<MetaDataType>>();
	const [targetType, setTargetType] = useState<Maybe<AnalysisTargetType>>();
	const [changedPeaks, setChangedPeaks] = useState<Partial<QuantitivePeakType>[]>([]);
	const [isAnalysisEditable, setIsAnalysisEditable] = useState<Maybe<boolean>>(false);

	const updateQuantitativeStep = (type: Maybe<QuantitativeStep>) => setQuantitativeStep(type);

	const updateAddedERMs = (type?: NmrSubstance[]) => setAddedERMs(type as NmrSubstance[]);

	const updateNmrDevice = (type: Maybe<NmrDevice>) => setNmrDevice(type);

	const updateMeasurementType = (type: Maybe<QualitativeOrQuantitative>) => setMeasurementType(type);

	const updateMetaData = (type: Maybe<MetaDataType>) => setMetaData(type);

	const updateTargetType = (type: Maybe<AnalysisTargetType>) => setTargetType(type);

	const updateChangedPeaks = (type?: Partial<QuantitivePeakType>[]) => setChangedPeaks(type ?? []);

	const updateAnalysisEditable = (type?: Maybe<boolean>) => setIsAnalysisEditable(type);

	return (
		<AnalysisContext.Provider
			value={{
				quantitativeStep,
				updateQuantitativeStep,
				addedERMs,
				updateAddedERMs,
				nmrDevice,
				updateNmrDevice,
				measurementType,
				updateMeasurementType,
				targetType,
				updateTargetType,
				metaData,
				updateMetaData,
				changedPeaks,
				updateChangedPeaks,
				isAnalysisEditable,
				updateAnalysisEditable,
			}}
		>
			{children}
		</AnalysisContext.Provider>
	);
};
