import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getParams, setParams, useFilterSearchParams, useService } from '@hooks';
import { SortOrder, UserRole } from '@models';
import { irAnalysisService, irFilterService } from '@services';
import { Box, Stack } from '@mui/material';
import { IrCompletedHistoryLegend, completeColumns } from '@components/ir-portal';
import { DataTableBackend } from '@components/common/DataTableBackend';

import { userSelector } from '@store/slices/common/common.slice';
import { useSelector } from 'react-redux';

export const IrCompletedAnalysisTable: FC = () => {
	const { searchParams, filtering, setFiltering } = useFilterSearchParams('', SortOrder.RESET);
	const [typedString, setTypedString] = useState('');

	const { t } = useTranslation('common');
	const { t: tPortal } = useTranslation('irportal');

	const { data: filterData } = useService(() => irFilterService.getIrAnalysisHistoryFilter(true));

	const { data: historyData, loading } = useService(() => {
		setTypedString(getParams(searchParams).getQuery());
		setParams(searchParams).setCompleted('true');
		return irAnalysisService.getAllWithSearchParams(searchParams);
	}, [searchParams]);

	const user = useSelector(userSelector);
	const isAdmin = user?.roles.includes(UserRole.LAB_ADMIN);

	return (
		<Stack>
			<DataTableBackend
				data={historyData ? historyData.data : ([] as any)}
				columns={completeColumns(t)}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: historyData?.totalPages ?? 1 }}
				searchText={typedString}
				onSearchTextChange={setTypedString}
				searchPlaceholder={
					isAdmin
						? tPortal('analysis-history.completed-analysis-search-placeholder-lab-admin')
						: tPortal('analysis-history.completed-analysis-search-placeholder-lab-member')
				}
				sx={{ visibility: loading ? 'hidden' : 'visible', marginX: 2 }}
				headerCellProps={{ sx: { minWidth: 60 } }}
				searchFieldProps={{ sx: { maxWidth: 650, width: 650 } }}
				filterData={filterData?.options}
				onFilter={setFiltering}
				filteringSelections={filtering}
			/>
			{!loading && historyData && historyData.data.length > 0 && (
				<Box sx={{ mt: '1.5rem' }}>
					<IrCompletedHistoryLegend />
				</Box>
			)}
		</Stack>
	);
};
