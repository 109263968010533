import { FC, useState, useEffect } from 'react';
import { PlotParams } from 'react-plotly.js';
import { Stack, SxProps } from '@mui/material';
import { IUploadedFile } from '@models';
import { RxUtils } from '@utils/Rx';
import { SampleFileInfo, fileService, irAnalysisService, irDrmService } from '@services';

import { useParams } from 'react-router-dom';
import { IrChart } from '../IrChart';
import { getTransMissionData } from '@utils';
import { useIrSettingsSelector } from '@store/slices/ir/ir.slice';
import { useSelector } from 'react-redux';
import { useIrAnalysis } from '@hooks';

interface ISpectrumChartType {
	analysisId?: number;
	file?: IUploadedFile;
	fileUrl?: string;
	hideZoomIcon?: boolean;
	sx?: SxProps;
	enableZoom?: boolean;
	color?: string;
	layout?: PlotParams['layout'];
	title?: string;
	axisTitle?: string;
	yaxisTitle?: string;
	uniqueId?: string;
	referenceMaterialId?: number;
	createRefernceId?: number;
	editing?: boolean;
	isDisabled?: boolean;
}

export const IrSpectrumChart: FC<ISpectrumChartType> = ({
	analysisId,
	file,
	fileUrl,
	hideZoomIcon = false,
	sx,
	enableZoom = true,
	color,
	layout,
	title,
	axisTitle,
	uniqueId,
	referenceMaterialId,
	createRefernceId,
	editing,
	isDisabled,
}) => {
	const [graphData, setGraphData] = useState<SampleFileInfo>({
		x: [],
		y: [],
		meta: {
			title: '',
			firstx: '',
			lastx: '',
		},
	});

	const { id = '' } = useParams<{ id: string }>();
	const irSettings = useSelector(useIrSettingsSelector);
	const [displaySettingsToggle, setDisplaySettingsToggle] = useState<boolean>(irSettings?.transmissionSpectra);
	const { updateMetaData } = useIrAnalysis();

	const handleDisplaySettings = (): void => {
		setDisplaySettingsToggle(!displaySettingsToggle);
	};

	const fetchSpectrumData = (specData: any) => {
		for (let i = 0; i < specData?.data['history'].length; i++) {
			//TODO consolidate into one function
			if (specData?.data['history'][`${i}`]['name'] === 'apply_range_harmonization_2') {
				const xAry: number[] = [];
				const metaData = specData.data['metadata'];
				for (let k = specData?.data['history'][`${i}`]['x'].start; k >= specData?.data['history'][`${i}`]['x'].stop; k--) {
					xAry.push(k);
				}
				setGraphData({
					x: xAry,
					y: specData?.data['history'][`${i}`]['y'],
					meta: metaData,
				});
				updateMetaData(metaData);
			}
		}
	};

	const fetchDataBasedOnService = async (isCreateReference: number | undefined) => {
		const idToFetch = isCreateReference ? createRefernceId || 0 : +id;
		const service = isCreateReference ? irDrmService : irAnalysisService;

		RxUtils.promisify(service.getSpectrumData(idToFetch), fetchSpectrumData);
	};

	useEffect(() => {
		if (file || editing) {
			fetchDataBasedOnService(createRefernceId);
		} else if (analysisId) {
			RxUtils.promisify(irAnalysisService.getFiles(analysisId), (data) => {
				if (data.length > 0) {
					RxUtils.promisify(irAnalysisService.downloadFile(analysisId, data[0].id), () => {
						//TODO consolidate into one function
						RxUtils.promisify(irAnalysisService.getSpectrumData(+id), (specData) => {
							for (let i = 0; i < specData?.data['history'].length; i++) {
								if (specData?.data['history'][`${i}`]['name'] === 'apply_range_harmonization_2') {
									const xAry: number[] = [];
									for (
										let k = specData?.data['history'][`${i}`]['x'].start;
										k >= specData?.data['history'][`${i}`]['x'].stop;
										k--
									) {
										xAry.push(k);
									}
									setGraphData({
										x: xAry,
										y: specData?.data['history'][`${i}`]['y'],
										meta: specData.data['metadata'],
									});
								}
							}
						});
					});
				}
			});
		} else if (fileUrl) {
			RxUtils.promisify(fileService.getFileFromUrl(fileUrl), () => {
				RxUtils.promisify(irAnalysisService.getSpectrumData(+id), (specData) => {
					for (let i = 0; i < specData?.data['history'].length; i++) {
						//TODO consolidate into one function
						if (specData?.data['history'][`${i}`]['name'] === 'apply_range_harmonization_2') {
							const xAry: number[] = [];
							for (
								let k = specData?.data['history'][`${i}`]['x'].start;
								k >= specData?.data['history'][`${i}`]['x'].stop;
								k--
							) {
								xAry.push(k);
							}
							setGraphData({
								x: xAry,
								y: specData?.data['history'][`${i}`]['y'],
								meta: specData.data['metadata'],
							});
						}
					}
				});
			});
		}
	}, [analysisId, file, fileUrl, displaySettingsToggle]);

	return (
		<Stack sx={{ width: '100%', display: 'flex', alignItem: 'center', justifyContent: 'center' }} data-testid="IrSpectrumChart">
			<IrChart
				subtitleVisibility={false}
				subTitle=""
				className={`c${referenceMaterialId}-spectrum-chart`}
				enableZoom={enableZoom}
				title={title || graphData?.meta.title}
				hideZoomIcon={hideZoomIcon}
				divId={uniqueId}
				displaySettingsToggle={handleDisplaySettings}
				isDeactivated={isDisabled}
				data={[
					{
						y: displaySettingsToggle ? getTransMissionData([...graphData.y]) : graphData.y,
						x: graphData?.x,
						type: 'scatter',
						mode: 'lines',
						marker: { color: color || 'gray' },
						direction: 'counterclockwise',
						name: 'Sample spectrum',
					},
				]}
				sx={sx}
				useResizeHandler
				style={{ width: '100%', height: '100%' }}
				layout={{
					showlegend: true,
					legend: {
						traceorder: 'reversed',
						yanchor: 'bottom',
						y: 1.1,
						xanchor: 'center',
						x: 0.4,
						orientation: 'h',
						bgcolor: 'transparent',
						bordercolor: '#D6D6D6',
						borderwidth: 1,
						font: { color: '#000000', size: 14 },
					},
					autosize: true,
					xaxis: {
						range: [graphData?.meta?.firstx, graphData?.meta?.lastx],
						showdividers: false,
						showline: true,
						zeroline: false,
						title: axisTitle,
					},
					yaxis: {
						showdividers: false,
						showline: true,
						zeroline: false,
						title: displaySettingsToggle ? 'Transmission [%]' : 'Absorbance [A.U.]',
					},
					...layout,
				}}
			/>
		</Stack>
	);
};
