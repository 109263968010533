/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { FormikTextfield, GenericCard, OverflowText } from '@components/common';
import { IrAccessory, notificationService } from '@services';
import { mainTitleSpacer, Tr } from '@utils';
import { useService } from '@hooks';
import { ReactComponent as Counter1 } from '@material-symbols/svg-600/outlined/counter_1-fill.svg';
import { ReactComponent as Counter2 } from '@material-symbols/svg-600/outlined/counter_2-fill.svg';
import { ReactComponent as Counter3 } from '@material-symbols/svg-600/outlined/counter_3-fill.svg';
import AddIcon from '@mui/icons-material/Add';
import { PortalPageRoutes } from '@models/router';
import { MeasurementTypeListForIr } from '@components/ir-portal/MeasurementTypeListForIr';
import { useLocation } from 'react-router-dom';
import { irAccessoryService } from '@services';
import { RxUtils } from '@utils/Rx';
import { PeakRangeInput } from '@components/ir-portal/PeakRangeInput';

type FormType = 'none' | 'newDevice' | 'recalibrate' | 'newCalibration' | 'healthCheck';
export interface AccessoryData {
	accessoryName: string;
	measuremntTypeAccessory: number;
	deviceId: number;
}

const deviceData: AccessoryData = {
	accessoryName: '',
	measuremntTypeAccessory: 0,
	deviceId: 0,
};
const formikStyle = {
	marginBottom: '1rem',
};

export const IrAddAccessory: FC = () => {
	const navigate = useNavigate();
	const [addNewCalibration, setAddNewCalibration] = useState(false);
	const { t } = useTranslation('irportal');
	const { state } = useLocation();
	Object.assign(deviceData, state);

	const { data: accessoryNamesResponse } = useService(() => irAccessoryService.getAccessories(deviceData.deviceId, 'all', false));
	// TO DO: USE CLASSES AS FORMIK EXTENDABLES, CREATE SEPERATE VALIDATION SCHEMAS
	const formik = useFormik<
		Extendable<{
			accessoryName: string;
			measuremntTypeAccessory: number;
			type: FormType;
			neverExpires: boolean;
			validityDuration: number;
		}>
	>({
		initialValues: {
			measuremntTypeAccessory: 0,
			accessoryName: '',
			neverExpires: false,
			validityDuration: '30',
		},
		validationSchema: yup.object({
			accessoryName: yup
				.string()
				.trim()
				.max(256)
				.required(t('device-management.field-error.accessory-name'))
				.notOneOf(
					accessoryNamesResponse?.map((it) => it.accessoryName) || [],
					t('device-management.field-error.accessory-name-not-unique'),
				),

			measuremntTypeAccessory: yup
				.string()
				.trim()
				.required(t('device-management.field-error.measurementtypeaccessory-required'))
				.nullable(),
		}),
		initialTouched: {
			measuremntTypeAccessory: false,
			accessoryName: false,
		},

		onSubmit: () => {
			notificationService.clear();
			RxUtils.promisify(
				irAccessoryService.addAccessory(
					formik.values.accessoryName,
					formik.values.measuremntTypeAccessory,
					deviceData.deviceId,
					formik.values.neverExpires,
					formik.values.validityDuration,
				),
				(device: IrAccessory) => {
					console.log(device);
					navigate(`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.GO_TO_DEVICE}/${deviceData.deviceId}`, {
						state: {
							accessoryName: formik.values.accessoryName,
							measuremntTypeAccessory: formik.values.measuremntTypeAccessory,
						},
					});
				},
				(err) => {
					console.log(err, 'er...');
				},
			);
		},
	});

	useEffect(() => {
		formik.validateForm();
	}, []);

	const ExistingDeviceHeader = () => {
		return (
			<Stack direction="row" alignSelf="flex-end">
				<Button variant="outlined" onClick={() => (setAddNewCalibration(true), formik.setFieldValue('type', 'newCalibration'))}>
					<AddIcon sx={{ marginRight: 1 }} />
					<Tr.IrPortal path="device-management.add-calibration" />
				</Button>
			</Stack>
		);
	};

	const onExpiryChange = (event: { target: { checked: any } }) => {
		formik.setFieldValue('neverExpires', event.target.checked);
		formik?.setFieldValue('validityDuration', event.target.checked ? '' : 30);
	};

	return (
		<form onSubmit={formik.handleSubmit}>
			<Stack direction="row" justifyContent="space-between" sx={{ marginBottom: mainTitleSpacer }}>
				<OverflowText variant="h1" sx={{ color: 'text.secondary', width: '100%', maxWidth: 'calc(100% - 25rem)' }}>
					<Tr.IrPortal path="device-management.accessory-details" />
				</OverflowText>
				{!deviceData?.accessoryName || addNewCalibration ? (
					<Stack direction="row" alignSelf="flex-end">
						<Button
							variant="outlined"
							sx={{ marginRight: '1rem' }}
							onClick={() => {
								navigate(-1);
							}}
						>
							<Tr.IrPortal path="device-management.cancel" />
						</Button>
						<Button
							disabled={!formik.isValid}
							type="submit"
							variant="contained"
							disableElevation
							sx={{ backgroundColor: 'primary.main' }}
						>
							<Tr.IrPortal path="device-management.create-device" />
						</Button>
						{/* </Tooltip> */}
					</Stack>
				) : (
					<ExistingDeviceHeader />
				)}
			</Stack>
			<Grid data-testid="device-wrapper-id" container columnSpacing={2}>
				<Grid item xs={6} height={1}>
					<GenericCard sx={{ height: '100%' }}>
						<Stack>
							<Stack marginBottom={3}>
								<Stack direction="row">
									<Counter1 width={24} height={24} fill="#01884C" />
									<Typography variant="h5" color="text.primary" marginLeft={1}>
										{t('device-management.accessory-name')}
									</Typography>
								</Stack>
								<Typography variant="pg-m" sx={{ marginY: 1.5 }}>
									{t('device-management.accessory-name-caption')}
								</Typography>
								<FormikTextfield
									sx={formikStyle}
									formikApi={formik}
									name="accessoryName"
									inputProps={{ maxLength: 256 }}
									required
									placeholder={t('device-management.device-name-placeholder')}
									onChange={(event) => formik.setFieldValue('accessoryName', event.target.value)}
								/>
							</Stack>

							<Stack>
								<Stack direction="row">
									<Counter2 width={24} height={24} fill="#01884C" />
									<Typography variant="h5" color="text.primary" marginLeft={1}>
										{t('device-management.measurementtype-accessory')}
									</Typography>
								</Stack>
								<Typography variant="pg-m" sx={{ marginY: 1.5 }}>
									{t('device-management.measurementtypeaccessory-name-caption')}
								</Typography>
								<MeasurementTypeListForIr name="measuremntTypeAccessory" required={false} formik={formik} />
							</Stack>

							<Stack marginTop={3}>
								<Stack direction="row">
									<Counter3 width={24} height={24} fill="#01884C" />
									<Typography variant="h5" color="text.primary" marginLeft={1}>
										{t('device-management.accessory-expiry')}
									</Typography>
								</Stack>
								<Typography variant="pg-m" sx={{ marginY: 1.5 }}>
									{t('device-management.accessory-expiry-caption')}
								</Typography>
								<PeakRangeInput
									valueChange={(val) => formik?.setFieldValue('validityDuration', val)}
									value={formik?.values['validityDuration']}
									disabled={formik?.values.neverExpires}
									title={''}
									placeholder={t('device-management.accessory-expiry-placeholder')}
									inputProps={{ 'aria-label': 'expiry-id' }}
									suffixforNumeric={false}
									rangeMax={1000}
								/>
								<FormControlLabel
									sx={{ marginRight: '0', my: 1.5 }}
									control={
										<Checkbox
											data-testid="check-consent-id"
											name="neverExpires"
											checked={formik?.values.neverExpires}
											onChange={onExpiryChange}
											sx={{ marginTop: '-5px' }}
										/>
									}
									label={<Tr.IrPortal path="Calibration should never expire" />}
								/>
							</Stack>
						</Stack>
					</GenericCard>
				</Grid>
			</Grid>
		</form>
	);
};
