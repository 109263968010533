import Plotly from 'plotly.js-dist-min';
import {
	IAnalysisReportImages,
	IReportImageModel,
	NmrAnalysisResult,
	fileService,
	loadingSpinnerOverlayService,
	nmrAnalysisService,
} from '@services';
import { RxUtils } from '../Rx';
import { fromJCAMP } from 'nmr-parser';
import { findMax, findMin } from '../Type';
import { PromiseUtils } from '@utils/PromiseUtils';
import { SpectraColorCodes } from '@utils/Transmission';

// The worst code I have ever seen in my life.
// eslint-disable-next-line @typescript-eslint/no-var-requires
const SmilesDrawer = require('smiles-drawer') as any;

interface ICordinate {
	x: number[];
	y: number[];
}

const BASE64_KEY = 'data:image/png;base64,';
const PROMISE_RESOLVER = 'Molecular structure promise resolved.';

const drawer = new SmilesDrawer.SmiDrawer({
	width: 450,
	height: 450,
	compactDrawing: false,
	themes: {
		light: {
			C: '#000',
			O: '#000',
			N: '#000',
			F: '#000',
			CL: '#000',
			BR: '#000',
			I: '#000',
			P: '#000',
			S: '#000',
			B: '#000',
			SI: '#000',
			H: '#000',
			BACKGROUND: '#fff',
		},
	},
});

const drawSpectrum = async (ermChartElement: HTMLElement, ermSpectrumData: ICordinate) => {
	return Plotly.newPlot(
		ermChartElement,
		[
			{
				x: ermSpectrumData.x,
				y: ermSpectrumData.y,
				type: 'scatter',
				mode: 'lines',
				marker: { color: 'gray' },
				direction: 'counterclockwise',
			},
		],
		{
			autosize: true,
			width: 600,
			height: 400,
			margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
			xaxis: {
				range: [10, 0],
				showdividers: false,
				showline: true,
				zeroline: false,
			},
		},
	);
};

const getJDXData = async (url: string): Promise<ICordinate> => {
	return new Promise<{ x: number[]; y: number[] }>((resolve) => {
		RxUtils.promisify(fileService.getFileFromUrl(url), (jdxFile) => {
			const parsedFile = fromJCAMP(jdxFile.replaceAll('$$ ##$', '##').replaceAll('\r\n$$', '\r\n##$$empty'));
			const targetFile = parsedFile.length > 1 ? parsedFile[1] : parsedFile[0];
			const yData = targetFile?.dependentVariables[0].components[0].data.y;

			const sampleMaxVal = yData ? findMax(yData) : 0;
			const sampleMinVal = yData ? findMin(yData) : 0;
			const sampleDiffMinMax = sampleMaxVal - sampleMinVal;

			resolve({
				x: targetFile?.dependentVariables[0].components[0].data.x,
				y: yData?.map((item: number) => (item - sampleMinVal) / sampleDiffMinMax),
			});
		});
	});
};

export const sendImage = async (analysisResult: NmrAnalysisResult, cb: (pdfUrl: string) => void) => {
	loadingSpinnerOverlayService.increment();
	const returnedModel: IAnalysisReportImages = { substanceImages: [] };
	const targetReferenceJDXResults = {};

	for (let i = 0; i < analysisResult.referenceMaterials?.length || 0; i++) {
		const { smilesCode, casNumber } = analysisResult.referenceMaterials[`${i}`];
		const molecularStructureImg = document.createElement('img');
		const stackedElement = document.createElement('div');
		stackedElement.style.height = '200px';
		const ermChartElement = document.createElement('div');

		try {
			const imgPromise = new Promise((resolve, reject) => {
				drawer.draw(
					smilesCode.trim(),
					molecularStructureImg,
					'light',
					() => {
						resolve(PROMISE_RESOLVER);
					},
					(e) => {
						reject(e);
					},
				);
			});

			await Promise.resolve(imgPromise).catch();
		} catch (e) {
			//
		}

		const tempObj: Partial<IReportImageModel> = {};
		await PromiseUtils.delay(() => {
			tempObj.moleculeImage = molecularStructureImg.src.replace(BASE64_KEY, '');
			tempObj.casNumber = casNumber;
			returnedModel.substanceImages.push(tempObj);
		}, 50);

		const targetResult = analysisResult.resultMatches.filter((item) => item.casNumber === casNumber);
		for (let j = 0; j < targetResult.length; j++) {
			const result = targetResult[`${j}`];
			let ermSpectrumData: ICordinate;
			let sampleData: ICordinate;
			if (!targetReferenceJDXResults[result.rmSpectrum]) {
				ermSpectrumData = await getJDXData(result.rmSpectrum);
				targetReferenceJDXResults[result.rmSpectrum] = ermSpectrumData;
			} else {
				ermSpectrumData = targetReferenceJDXResults[result.rmSpectrum];
			}

			if (!targetReferenceJDXResults[result.sampleSpectrum]) {
				sampleData = await getJDXData(result.sampleSpectrum);
				targetReferenceJDXResults[result.sampleSpectrum] = sampleData;
			} else {
				sampleData = targetReferenceJDXResults[result.sampleSpectrum];
			}

			await Plotly.newPlot(
				stackedElement,
				[
					{
						y: sampleData.y,
						x: sampleData.x,
						type: 'scatter',
						marker: {
							color: analysisResult.isQuantitative() ? SpectraColorCodes.VIBRANT_MAGENTA : SpectraColorCodes.RICH_GREEN,
						},
						direction: 'counterclockwise',
						name: 'Input Spectrum',
					},
					!analysisResult.isQuantitative()
						? {
								y: ermSpectrumData.y,
								x: ermSpectrumData.x,
								type: 'scatter',
								marker: { color: SpectraColorCodes.VIBRANT_MAGENTA },
								direction: 'counterclockwise',
								name: 'PredictedSpectrum',
								yaxis: 'y2',
							}
						: {},
				],
				{
					margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
					height: 250,
					width: 750,
					autosize: true,
					hovermode: 'x unified',
					legend: {
						y: 5,
						xanchor: 'center',
						x: 0.5,
						orientation: 'h',
						bgcolor: 'transparent',
						borderwidth: 1,
						bordercolor: '#E1E1EA',
						font: { color: '#0F1A2E', size: 12 },
					},
					showlegend: true,
					yaxis2: { domain: [0.45, 1], showline: true, zeroline: false, showticklabels: false, griddash: 'dashdot' },
					xaxis: {
						showdividers: false,
						showline: true,
						zeroline: false,
						range: [10, 0],
						gridcolor: '#A9A9BA',
						griddash: 'dot',
					},
					yaxis: { domain: [0, 0.5], griddash: 'dashdot', showline: true },
				},
				{},
			);

			await drawSpectrum(ermChartElement, ermSpectrumData);

			const stackedImage = await Plotly.toImage(stackedElement, { scale: 2, format: 'png', width: 750, height: 225 });
			const ermImage = await Plotly.toImage(ermChartElement);

			tempObj.spectrumSeperated = stackedImage.replace(BASE64_KEY, '');
			tempObj.referenceMaterialSpectrum = ermImage.replace(BASE64_KEY, '');
		}
	}

	if (analysisResult.isQuantitativeNonTargeted()) {
		const stackedElement = document.createElement('div');
		stackedElement.style.height = '200px';
		const ermChartElement = document.createElement('div');

		const tempObj: Partial<IReportImageModel> = {};
		await PromiseUtils.delay(() => {
			returnedModel.substanceImages.push(tempObj);
		}, 50);
		const sampleData = await getJDXData(analysisResult.spectrumData.spectrum);
		await Plotly.newPlot(
			stackedElement,
			[
				{
					y: sampleData.y,
					x: sampleData.x,
					type: 'scatter',
					marker: { color: '#EB3C96' },
					direction: 'counterclockwise',
					name: 'Input Spectrum',
				},
			],
			{
				margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
				height: 250,
				width: 750,
				autosize: true,
				hovermode: 'x unified',
				legend: {
					y: 5,
					xanchor: 'center',
					x: 0.5,
					orientation: 'h',
					bgcolor: 'transparent',
					borderwidth: 1,
					bordercolor: '#E1E1EA',
					font: { color: '#0F1A2E', size: 12 },
				},
				showlegend: true,
				yaxis2: { domain: [0.45, 1], showline: true, zeroline: false, showticklabels: false, griddash: 'dashdot' },
				xaxis: {
					showdividers: false,
					showline: true,
					zeroline: true,
					range: [10, 0],
					gridcolor: '#A9A9BA',
					griddash: 'dot',
				},
				yaxis: { domain: [0, 0.5], griddash: 'dashdot' },
			},
			{},
		);

		await drawSpectrum(ermChartElement, sampleData);

		const stackedImage = await Plotly.toImage(stackedElement, { scale: 2, format: 'png', width: 750, height: 225 });

		tempObj.spectrumSeperated = stackedImage.replace(BASE64_KEY, '');
	}

	RxUtils.promisify(
		nmrAnalysisService.sendAnalysisImage(analysisResult.analysisId, returnedModel),
		(pdfUrl) => cb(pdfUrl),
		() => undefined,
		() => loadingSpinnerOverlayService.decrement(),
	);
};
