import { ReactNode, useState } from 'react';
import {
	MFA as MFAComponent,
	Login,
	PhoneNumber,
	Register,
	AuthLayout,
	ForgotPassword,
	SetNewPassword,
	ResetSuccessfully,
	MFASelection,
	UserConsent,
} from '@components/common';
import { authService, notificationService } from '@services';
import { checkChallenges, ILoginUserModel, LoginStep, MfaType, PortalPageRoutes } from '@models';
import { useNavigate } from 'react-router-dom';
import { PromiseUtils } from '@utils/PromiseUtils';

export const LoginFlow = () => {
	const [userInfo, setUserInfo] = useState<ILoginUserModel>();
	const [loginStep, setLoginStep] = useState<LoginStep>(LoginStep.LOGIN_STEP);
	const [mfaType, setMfaType] = useState<MfaType>(MfaType.SMS);
	const navigate = useNavigate();

	const LoginStepComponentMapping: Record<LoginStep, ReactNode> = {
		loginStep: (
			<Login
				onNewPassRequired={({ email, password, user }) => {
					setUserInfo({ ...userInfo, oldPassword: password, newPassword: password, user: user, email: email });
					setLoginStep(LoginStep.USER_CONSENT);
				}}
				onMFARequired={({ email, password, user }) => {
					setLoginStep(LoginStep.MFA);
					setUserInfo({ ...userInfo, user: user, email: email, newPassword: password, oldPassword: password });
				}}
				onPasswordReset={() => setLoginStep(LoginStep.RESET_PASSWORD)}
				onMFATypeSelection={({ email, password, user }) => {
					setUserInfo({ ...userInfo, oldPassword: password, newPassword: password, user: user, email: email });
					setLoginStep(LoginStep.MFA_SELECTION);
				}}
			/>
		),
		userConsent: (
			<UserConsent onBackClick={() => setLoginStep(LoginStep.LOGIN_STEP)} onNextClick={() => setLoginStep(LoginStep.REGISTER_STEP)} />
		),
		registerStep: (
			<Register
				onSuccessfullyRegister={({ firstName, lastName, newPassword }) => {
					setUserInfo({ ...userInfo, firstName, lastName, newPassword });
					setLoginStep(LoginStep.PHONE_NUMBER);
				}}
				onBackClick={() => setLoginStep(LoginStep.LOGIN_STEP)}
			/>
		),
		phoneNumber: (
			<PhoneNumber
				onSuccessfullyEntered={(phoneNumber) => {
					setUserInfo({ ...userInfo, phoneNumber: phoneNumber });
					PromiseUtils.runPromise(
						() => authService.completeUserPassword({ ...userInfo, phoneNumber: phoneNumber }),
						(registeredUser) => {
							if (registeredUser.nextStep.signInStep && registeredUser.nextStep.signInStep !== 'DONE') {
								const challenge = checkChallenges(registeredUser.nextStep.signInStep);
								if (challenge.isSmsMfa()) {
									setLoginStep(LoginStep.MFA);
									setUserInfo({ ...userInfo, user: registeredUser, phoneNumber: phoneNumber });
								} else {
									notificationService.sendError('Error while adding a user');
								}
							} else {
								navigate(`portal/${PortalPageRoutes.DASHBOARD}`);
							}
						},
						({ message }) => {
							notificationService.sendError(message);
							setLoginStep(LoginStep.LOGIN_STEP);
						},
					);
				}}
				onBackClick={() => setLoginStep(LoginStep.REGISTER_STEP)}
			/>
		),
		mfa: (
			<MFAComponent
				mfaType={mfaType}
				fromLogin={!userInfo?.phoneNumber}
				userInfo={userInfo}
				onResendCodeClick={(userData) => {
					setUserInfo({ ...userInfo, user: userData });
				}}
				onBackClick={() => {
					!userInfo?.phoneNumber ? setLoginStep(LoginStep.LOGIN_STEP) : setLoginStep(LoginStep.PHONE_NUMBER);
				}}
				goToLoginStep={() => setLoginStep(LoginStep.LOGIN_STEP)}
				onMFASelection={() => {
					userInfo?.email &&
						userInfo?.newPassword &&
						PromiseUtils.runPromise(
							() => authService.newLogin(userInfo.email || '', userInfo.newPassword || ''),
							(data) => {
								setUserInfo({ ...userInfo, user: data });
								setLoginStep(LoginStep.MFA_SELECTION);
							},
						);
				}}
			/>
		),
		resetPassword: (
			<ForgotPassword
				onSetNewPassword={(email, maskedMail) => {
					setUserInfo({ ...userInfo, maskedEmail: maskedMail, email: email });
					setLoginStep(LoginStep.SET_NEW_PASSWORD);
				}}
				onBackClick={() => setLoginStep(LoginStep.LOGIN_STEP)}
			/>
		),
		setNewPassword: (
			<SetNewPassword
				onResetSuccessfully={() => setLoginStep(LoginStep.RESET_SUCCESSFULLY)}
				gotoLogin={() => setLoginStep(LoginStep.LOGIN_STEP)}
				userInfo={userInfo}
			/>
		),
		resetSuccessfully: <ResetSuccessfully gotoLogin={() => setLoginStep(LoginStep.LOGIN_STEP)} />,
		mfaSelection: (
			<MFASelection
				onSMSSelect={() => {
					setMfaType(MfaType.SMS);
					setLoginStep(LoginStep.MFA);
				}}
				onTOTPSelect={() => {
					setMfaType(MfaType.TOTP);
					setLoginStep(LoginStep.MFA);
				}}
				goToLogin={() => setLoginStep(LoginStep.LOGIN_STEP)}
				userInfo={userInfo}
			/>
		),
	};

	return <AuthLayout>{LoginStepComponentMapping[`${loginStep}`]}</AuthLayout>;
};
