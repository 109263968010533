import { Column } from 'react-table';
import { Tr } from '@utils/Translation';
import { UsefulLinkUtils } from '@utils/Link';
import { useTranslation } from 'react-i18next';
import { FC, useContext, useMemo } from 'react';
import { DataTable, SpectrumChart } from '@components/common';
import { Typography, Card, Box, TypographyProps, Link, Stack } from '@mui/material';
import { QuantitivePeakType } from '@services/nmr/nmr-analysis/nmr-quantitive-spectrum.class';
import { ReactComponent as Check } from '@material-symbols/svg-600/outlined/check.svg';
import { ReactComponent as Close } from '@material-symbols/svg-600/outlined/close.svg';
import { AnalysisResultContext } from '../analysis-result-context';
import { NmrAnalysisCommentField } from '../NmrAnalysisCommentField';

const Cell: FC<TypographyProps> = ({ sx, ...props }) => (
	<Typography variant="pg-m" style={{ textAlign: 'left' }} sx={{ textAlign: 'center', wordBreak: 'break-word', ...sx }} {...props} />
);

export const InputSpectrum: FC = () => {
	const { t } = useTranslation('portal');
	const { analysisResultData } = useContext(AnalysisResultContext);
	if (!analysisResultData) return null;

	const { peaks, spectrum } = analysisResultData.spectrumData;

	const columns = useMemo<Column<QuantitivePeakType>[]>(
		() => [
			{
				accessor: 'isSelected',
				disableSortBy: true,
				Header: '',
				Cell: ({ value, row }) => (
					<Cell>
						{value ? (
							<Check width={20} height={20} fill={row.original.isSelected ? '#01884C' : '#A9A9BA'} />
						) : (
							<Close width={20} height={20} fill={row.original.isSelected ? '#E61E50' : '#A9A9BA'} />
						)}
					</Cell>
				),
			},
			{
				accessor: 'id',
				disableSortBy: true,
				Cell: ({ row }) => <Cell sx={{ color: row.original.isSelected ? 'inherit' : 'grey.400' }}>{row.index + 1}</Cell>,
				Header: <Tr.Portal path={'analysis-result.table.entry'} />,
			},
			{
				accessor: 'chemShift',
				disableSortBy: true,
				Cell: ({ value, row }) => <Cell sx={{ color: row.original.isSelected ? 'inherit' : 'grey.400' }}>{value || '-'}</Cell>,
				Header: <Tr.Portal path={'analysis-result.table.chemical-shift'} />,
			},
			{
				accessor: 'multiplicity',
				disableSortBy: true,
				Cell: ({ value, row }) => <Cell sx={{ color: row.original.isSelected ? 'inherit' : 'grey.400' }}>{value || '-'}</Cell>,
				Header: <Tr.Portal path={'analysis-result.table.multiplicity'} />,
			},
			{
				accessor: 'couplingConstant',
				disableSortBy: true,
				Cell: ({ value, row }) => <Cell sx={{ color: row.original.isSelected ? 'inherit' : 'grey.400' }}>{value || '-'}</Cell>,
				Header: <Tr.Portal path={'analysis-result.table.coupling-constant'} />,
			},
			{
				accessor: 'numberOfH',
				disableSortBy: true,
				Cell: ({ value, row }) => <Cell sx={{ color: row.original.isSelected ? 'inherit' : 'grey.400' }}>{value || '-'}</Cell>,
				Header: <Tr.Portal path={'analysis-result.table.hydrogen-count'} />,
			},
			{
				accessor: 'relativeIntegral',
				disableSortBy: true,
				Cell: ({ value, row }) => <Cell sx={{ color: row.original.isSelected ? 'inherit' : 'grey.400' }}>{value || '-'}</Cell>,
				Header: <Tr.Portal path={'analysis-result.table.relative-integral'} />,
			},
			{
				accessor: 'absoluteIntegral',
				disableSortBy: true,
				Cell: ({ value, row }) => <Cell sx={{ color: row.original.isSelected ? 'inherit' : 'grey.400' }}>{value || '-'}</Cell>,
				Header: <Tr.Portal path={'analysis-result.table.absolute-integral'} />,
			},
		],
		[],
	);

	const isFootnotesActive = peaks.some(({ multiplicity }) => multiplicity === 'sc');

	return (
		<Card variant="elevation" sx={{ padding: '2rem 1.5rem 2.5rem' }}>
			{!analysisResultData.isError() && (
				<>
					<Typography variant="h3" mb={2} sx={{ color: 'success.main' }}>
						{t('analysis-result.input-spectrum')}
					</Typography>
					<Stack
						sx={{
							mb: 4,
							overflow: 'hidden',
							border: '1px solid ',
							borderRadius: '0.5rem',
							borderColor: 'grey.200',
							padding: '0.5rem 1rem 0 0',
						}}
					>
						<SpectrumChart
							color="#e73c98"
							fileUrl={spectrum}
							enableZoom={false}
							subtitleVisibility={true}
							uniqueId="input-spectrum-chart"
							subTitle={<Tr.Portal path="analysis-result.intensity" />}
							layout={{
								showlegend: true,
								hovermode: 'x unified',
								margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
								legend: {
									y: 5,
									x: 0.5,
									borderwidth: 1,
									orientation: 'h',
									xanchor: 'center',
									bgcolor: 'transparent',
									bordercolor: '#E1E1EA',
									font: { color: '#0F1A2E', size: 12 },
								},
								xaxis: {
									range: [10, 0],
									showline: true,
									zeroline: false,
									showdividers: false,
								},
								yaxis: {
									showline: true,
								},
							}}
						/>
					</Stack>
				</>
			)}

			<Typography variant="h3" mb={2} sx={{ color: 'success.main' }}>
				{t('analysis-result.integrated-peaks-table')}
			</Typography>
			<Box
				sx={{
					marginBottom: 3,
					border: '1px solid',
					borderRadius: '12px',
					height: 'fit-content',
					borderColor: 'grey.200',
				}}
			>
				<DataTable
					data={peaks}
					columns={columns}
					sx={{
						'& .MuiTableBody-root 	.MuiTableRow-root': {
							'&:last-child': {
								'& .MuiTableCell-root': {
									borderBottom: 'none',
								},
							},
							'& .MuiTableCell-root': {
								textAlign: 'center',
								padding: '0 1rem',
								borderLeft: '1px solid',
								borderColor: 'grey.200',
								'&:first-child': {
									borderLeft: 'none',
								},
							},

							height: '2.5rem',
						},
						'& .MuiTableRow-head': {
							'& .MuiTableCell-head': {
								padding: '0.7rem 1rem',
								borderLeft: '1px solid',
								borderColor: 'grey.200',
								'&:first-child': {
									borderLeft: 'none',
								},
								'& div': {
									display: 'flex',
									justifyContent: 'center',
								},
								'& span': {
									fontWeight: 700,
								},
							},
						},
					}}
				/>
			</Box>

			{isFootnotesActive && (
				<Stack mb={2}>
					<Typography variant="label-s" mb={1}>
						<Tr.Portal path="analysis-result.footnotes" />
					</Typography>
					<Typography variant="pg-m" sx={{ color: 'grey.800' }}>
						<Tr.Portal path="analysis-result.sc" />
					</Typography>
				</Stack>
			)}

			<Stack mb={4}>
				<Typography variant="label-s" mb={1}>
					<Tr.Portal path="analysis-result.other-notes" />
				</Typography>
				<Typography variant="pg-m" sx={{ color: 'grey.800' }}>
					<Tr.Portal path="analysis-result.find-additional" />
					<Link
						target="_blank"
						href={UsefulLinkUtils.FAQ}
						sx={{ color: 'text.secondary', marginLeft: 0.5, textDecoration: 'none' }}
					>
						<Tr.Portal path="analysis-result.faq" />
					</Link>
				</Typography>
			</Stack>

			<NmrAnalysisCommentField />
		</Card>
	);
};
