import { FormikApiType, FormikTextfield } from '@components/common';
import { FormikDateRangePicker } from '@components/common/FormikDateRangePicker';
import { Divider, FormControlLabel, Grid, Radio, RadioGroup, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { FC, useState, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikTechnologyAdd } from './FormikTechnologyAdd';
import { SubscriptionType } from '@models/subscribe/subscription.types.enum';
import { PresetDate } from '../Subscription';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { NoPaidSubscription } from './NoPaidSubscription';
import { CurrencySelector } from './CurrencySelector';
import { BudgetLimitExceededModal } from './BudgetLimitExceededModal/BudgetLimitExceededModal';
import { CurrencyLimitMapping, SubscriptionDefaults } from '@models/subscribe';
interface IPaidSubscriptionFormProps {
	formik?: FormikApiType;
	required?: boolean;
}

const labelStyle: SxProps = {
	color: 'grey.800',
};
const textInputStyle: SxProps = {
	fieldset: {
		borderColor: 'grey.200',
		padding: 0,
		borderWidth: '1.5px',
	},
	input: {
		backgroundColor: 'grey.50',
	},
	'& .MuiFormHelperText-root.Mui-error': {
		position: 'absolute',
		top: '93%',
	},
};

const BudgetLimitTitleMapping: Record<SubscriptionType, ReactNode> = {
	[SubscriptionType.POST_PAID]: (
		<Stack direction="row" justifyContent="space-between">
			<Typography variant="label-s" className="required" sx={labelStyle}>
				<Tr.Admin path="organization.budget-limit" />
			</Typography>
			<Tooltip
				title={
					<Stack>
						<Typography variant="h6">
							<Tr.Admin path="organization.budget-limit" />
						</Typography>
						<Typography variant="pg-xs">
							<Tr.Admin path="organization.budget-limit-tooltip" />
						</Typography>
					</Stack>
				}
			>
				<InfoOutlinedIcon sx={{ width: 'fit-content', height: '15px' }} />
			</Tooltip>
		</Stack>
	),
	[SubscriptionType.PRE_PAID]: (
		<Typography variant="label-s" className="required" sx={labelStyle}>
			<Tr.Admin path="organization.budget" />
		</Typography>
	),
	[SubscriptionType.FREE]: null,
};

export const PaidSubscriptionForm: FC<IPaidSubscriptionFormProps> = ({ formik, required }) => {
	const { t } = useTranslation('admin');
	const rangePresets = PresetDate([formik?.values?.paidSubscription?.startDate, formik?.values.paidSubscription?.endDate], () => {
		formik?.setFieldValue('paidSubscription.startDate', undefined);
		formik?.setFieldValue('paidSubscription.endDate', undefined);
	});
	const [isBudgetLimitExceedApproved, setIsBudgetLimitExceedApproved] = useState<boolean>(false);
	const [isBudgetLimitModalOpened, setIsBudgetLimitModalOpened] = useState<boolean>(false);
	const [budgetStore, setBudgetStore] = useState<number>(formik?.values.paidBudget);

	useEffect(() => {
		if (!budgetStore) {
			setBudgetStore(formik?.values?.paidSubscription?.budget);
		}
	}, [formik?.values?.paidSubscription?.budget]);

	useEffect(() => {
		setIsBudgetLimitExceedApproved(false);
	}, [formik?.values.currency]);

	const onPaymentTypeChange = (value: string) => {
		formik?.setFieldValue('paidSubscription.paymentType', value);
		const currencyLimit = CurrencyLimitMapping[formik?.values?.paidSubscription?.currency];
		if (
			+formik?.values.paidSubscription.budget > currencyLimit &&
			!isBudgetLimitExceedApproved &&
			value === SubscriptionType.POST_PAID
		) {
			setIsBudgetLimitModalOpened(true);
			setBudgetStore(currencyLimit);
			return;
		}
	};

	const onBudgetChange = (value: string) => {
		if (
			+value > CurrencyLimitMapping[formik?.values?.paidSubscription?.currency] &&
			!isBudgetLimitExceedApproved &&
			formik?.values.paidSubscription?.paymentType === SubscriptionType.POST_PAID
		) {
			setIsBudgetLimitModalOpened(true);
			return;
		}
		setBudgetStore(+value);
	};

	return (
		<Stack height={1} sx={{ position: 'relative' }}>
			{required || formik?.values.paidSubscription.paymentType ? (
				<Grid container columnSpacing={6} rowSpacing={0}>
					{!required && (
						<Grid item xs={12}>
							<Typography variant="h4">
								<Tr.Admin path="organization.paid-subscription" />
							</Typography>
						</Grid>
					)}

					<Grid item xs={6} paddingTop={2.75}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<FormikDateRangePicker
									formik={formik}
									value={[formik?.values?.paidSubscription?.startDate, formik?.values.paidSubscription?.endDate]}
									onChange={(values) => {
										if (values) {
											formik?.setFieldValue('paidSubscription.startDate', values[0]);
											formik?.setFieldValue('paidSubscription.endDate', values?.[1]);
											formik?.validateForm();
										}
									}}
									firstTitle={t('organization.start-date')}
									secondTitle={t('organization.end-date')}
									name="paidSubscription.paidRange"
									errorText={t('organization.paid-date-range-required')}
									presets={rangePresets}
									status={
										!formik?.values?.paidSubscription?.startDate || !formik?.values?.paidSubscription.endDate
											? 'error'
											: ''
									}
									typographyProps={{
										sx: labelStyle,
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Stack>
									<Typography variant="label-s" className="required" sx={{ ...labelStyle, paddingBottom: 1.25 }}>
										<Tr.Admin path="organization.billing-type" />
									</Typography>
									{/* TO DO: CREATE DIFFERENT COMPONENT */}
									<RadioGroup
										value={formik?.values.paidSubscription?.paymentType}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => onPaymentTypeChange(event.target.value)}
										sx={{
											direction: 'row',
											height: '44px',
										}}
									>
										<Grid container spacing={3}>
											<Grid item xs={6}>
												<Stack
													sx={{
														border: '1.5px solid',
														borderColor: 'grey.200',
														paddingLeft: 2,
														borderRadius: '4px',
														width: '100%',
													}}
												>
													<FormControlLabel
														value={SubscriptionType.PRE_PAID}
														control={
															<Radio
																// eslint-disable-next-line @typescript-eslint/ban-ts-comment
																// @ts-ignore
																inputProps={{ 'data-testid': 'pre-paid-test-id' }}
															/>
														}
														label={
															<Typography variant="label-s" color="grey.800">
																<Tr.Admin path="organization.pre-paid" />
															</Typography>
														}
													/>
												</Stack>
											</Grid>

											<Grid item xs={6}>
												<Stack
													sx={{
														border: '1.5px solid',
														borderColor: 'grey.200',
														paddingLeft: 2,
														borderRadius: '4px',
														width: '100%',
													}}
												>
													<FormControlLabel
														value={SubscriptionType.POST_PAID}
														control={
															<Radio
																// eslint-disable-next-line @typescript-eslint/ban-ts-comment
																// @ts-ignore
																inputProps={{ 'data-testid': 'post-paid-test-id' }}
															/>
														}
														label={
															<Typography variant="label-s" color="grey.800">
																<Tr.Admin path="organization.post-paid" />
															</Typography>
														}
													/>
												</Stack>
											</Grid>
										</Grid>
									</RadioGroup>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack position="relative" spacing={3}>
									<FormikTextfield
										formikApi={formik}
										type="number"
										name="paidSubscription.budget"
										value={formik?.values?.paidSubscription.budget}
										data-testid="paid-budget-test-id"
										title={BudgetLimitTitleMapping[formik?.values?.paidSubscription.paymentType]}
										placeholder={
											formik?.values?.paymentType === SubscriptionType.PRE_PAID
												? t('organization.budget-explanation')
												: t('organization.budget-limit-explanation')
										}
										variant="outlined"
										sx={{ ...textInputStyle }}
										onKeyDown={(event) => {
											if (event?.key === '-' || event?.key === '+' || event?.key === '.' || event?.key === 'e') {
												event.preventDefault();
											}
										}}
										onChange={(event) => onBudgetChange(event.target.value)}
										InputProps={{
											endAdornment: <CurrencySelector formik={formik} />,
										}}
									/>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<FormikTextfield
									formikApi={formik}
									name="paidSubscription.comment"
									title={t('organization.subscription-comment')}
									placeholder={t('organization.subscription-comment-placeholder')}
									variant="outlined"
									sx={{
										fieldset: {
											borderWidth: '1.5px',
											borderColor: 'grey.200',
										},
										backgroundColor: 'grey.50',
										'.MuiInputBase-root': {
											padding: '7px 14px!important',
											backgroundColor: 'grey.50',
										},
									}}
									typographyProps={{ sx: { ...labelStyle, '&::after': { content: '""' } } }}
									multiline
									rows={4.5}
									containerProps={{ width: 1 }}
									inputProps={{ 'data-testid': 'subscription-comment-id', maxLength: 250 }}
								/>
							</Grid>
						</Grid>
					</Grid>
					{!required && <Divider sx={{ position: 'absolute', right: '50%', height: '90%', top: '5%' }} orientation="vertical" />}

					<Grid item xs={6} paddingTop={3}>
						<FormikTechnologyAdd formik={formik} />
					</Grid>
				</Grid>
			) : (
				<NoPaidSubscription
					onSave={() => {
						formik?.setFieldValue('paidSubscription.paymentType', SubscriptionType.PRE_PAID);
						formik?.setFieldValue('paidSubscription.budget', SubscriptionDefaults.BUDGET);
					}}
				/>
			)}
			{isBudgetLimitModalOpened && (
				<BudgetLimitExceededModal
					currentBudgetLimit={formik?.values?.paidSubscription.budget}
					budgetLimit={CurrencyLimitMapping[formik?.values.paidSubscription?.currency]}
					currency={formik?.values.paidSubscription?.currency}
					isOpen={isBudgetLimitModalOpened}
					onCancel={() => {
						formik?.setFieldValue('paidSubscription.budget', budgetStore);
						setIsBudgetLimitModalOpened(false);
					}}
					onSave={() => {
						setIsBudgetLimitExceedApproved(true);
						setIsBudgetLimitModalOpened(false);
					}}
				/>
			)}
		</Stack>
	);
};
