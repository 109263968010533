import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilterSearchParams, useService, setParams, getParams } from '@hooks';
import { SortOrder, UserRole } from '@models';
import { irAnalysisService, irFilterService } from '@services';
import { onGoingColumns } from '@components/ir-portal';
import { DataTableBackend } from '@components/common/DataTableBackend';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/slices/common/common.slice';

export const IrOngoingAnalysisTable: FC = () => {
	const { filtering, setFiltering, searchParams } = useFilterSearchParams('lastActivityDate', SortOrder.DESC);
	const [typedString, setTypedString] = useState('');

	const { t } = useTranslation('common');
	const { t: tPortal, ready } = useTranslation('irportal');
	const translate = (path: string) => (ready ? tPortal(path) : '');

	const { data: filterData } = useService(() => irFilterService.getIrAnalysisHistoryFilter());

	const { data: historyData, loading } = useService(() => {
		setTypedString(getParams(searchParams).getQuery());
		setParams(searchParams).setCompleted('false');
		return irAnalysisService.getAllWithSearchParams(searchParams);
	}, [searchParams]);

	const user = useSelector(userSelector);
	const isAdmin = user?.roles.includes(UserRole.LAB_ADMIN);

	return (
		<>
			<DataTableBackend
				data={historyData ? historyData.data : ([] as any)}
				columns={onGoingColumns(t, tPortal)}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: historyData?.totalPages ?? 1 }}
				searchText={typedString}
				searchPlaceholder={
					isAdmin
						? translate('analysis-history.ongoing-analysis-search-placeholder-lab-admin')
						: translate('analysis-history.ongoing-analysis-search-placeholder-lab-member')
				}
				onSearchTextChange={setTypedString}
				sx={{ visibility: loading ? 'hidden' : 'visible', marginX: 2 }}
				headerCellProps={{ sx: { minWidth: 60 } }}
				searchFieldProps={{ sx: { maxWidth: 600, width: 600 } }}
				filterData={filterData?.options}
				onFilter={setFiltering}
				filteringSelections={filtering}
			/>
		</>
	);
};
