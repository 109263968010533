import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'pdfjs-dist/build/pdf.worker.entry';
import { getDocument, PageViewport, PDFPageProxy } from 'pdfjs-dist';
import { format } from 'date-fns';
import { Button, IconButton, Link, Stack, Typography } from '@mui/material';
import { DateUtils, Tr, downloadFileFromLink } from '@utils';
import { NmrAnalysisResult as NmrAnalysisResultType } from '@services';
import { IModalDialogProps, ModalDialog } from '@components/common';
import Close from '@mui/icons-material/Close';
import { userSelector } from '@store/slices/common/common.slice';
import { MAX_RENDER_TRIAL } from '@models/fallback';

export const NmrAnalysisReport: FC<
	IModalDialogProps & { onClose: () => void; resultData: NmrAnalysisResultType; pdfUrl?: string | null }
> = ({ resultData, open, onClose, pdfUrl, ...props }) => {
	const { t } = useTranslation('portal');
	const user = useSelector(userSelector);
	const sendViaMail = (analysisTitle: string) => {
		const mailSubject = t('report-email.subject', { analysisTitle: analysisTitle });
		const mailBody = t('report-email.body', { name: user?.name, surname: user?.surname, url: window.location.href });
		return `mailto: ?subject=${mailSubject}&body=${encodeURIComponent(mailBody)}`;
	};

	const canvasRefs: RefObject<HTMLCanvasElement[] | null> = useRef([]);
	const [pageNum, setPageNum] = useState(0);
	const [trialsDone, setTrialsDone] = useState(0);

	const handleRetryCanvasRender = (viewport: PageViewport, page: PDFPageProxy, i: number) => {
		if (MAX_RENDER_TRIAL > trialsDone) {
			setTrialsDone((prev) => prev + 1);
			setTimeout(() => {
				handleCanvasRefRender(viewport, page, i);
			}, 500);
		}
	};

	const handleCanvasRefRender = (viewport: PageViewport, page: PDFPageProxy, i: number) => {
		if (canvasRefs.current && canvasRefs.current[i - 1]) {
			const canvas = canvasRefs.current[i - 1];
			const canvasContext = canvas.getContext('2d');
			canvas.height = viewport.height;
			canvas.width = viewport.width;
			// Render PDF page into canvas context.
			if (canvasContext) {
				const renderContext = {
					canvasContext: canvasContext,
					viewport: viewport,
				};
				page.render(renderContext as any);
			}
		} else if (canvasRefs.current) {
			handleRetryCanvasRender(viewport, page, i);
		}
	};

	const getPdfsViewer = () => {
		getDocument({
			url: pdfUrl as string,
			withCredentials: true,
			origin: window.location.origin,
			disableAutoFetch: true,
			disableStream: true,
			rangeChunkSize: 65536,
		}).promise.then((pageDoc) => {
			const numPages = pageDoc.numPages;
			setPageNum(numPages);

			for (let i = 1; i <= numPages; i++) {
				pageDoc.getPage(i).then((page) => {
					const viewport = page.getViewport({ scale: 4 });
					// Prepare canvas using PDF page dimensions.
					handleCanvasRefRender(viewport, page, i);
				});
			}
		});
	};

	useEffect(() => {
		pdfUrl && getPdfsViewer();
	}, [pdfUrl]);

	const renderPdfs = () => {
		if (canvasRefs.current) {
			return Array.from(Array(pageNum).keys()).map((_, i) => {
				return (
					<Stack key={i} spacing={2}>
						<canvas
							key={i}
							ref={(ref) => {
								if (canvasRefs.current && ref) {
									canvasRefs.current[`${i}`] = ref;
								}
							}}
						/>
						;
					</Stack>
				);
			});
		}
	};

	const [buttonDisable, setButtonDisable] = useState(true);

	useEffect(() => {
		if (pdfUrl) {
			setButtonDisable(false);
		}
	}, [pdfUrl]);

	return (
		<>
			<ModalDialog
				variant="primary"
				fullScreen
				open={open}
				sx={{ backgroundColor: 'transparent', zIndex: 22222 }}
				hideCloseIcon
				PaperProps={{ sx: { backgroundColor: 'transparent' } }}
				BackdropProps={{ sx: { background: 'rgba(0, 0, 0, 0.4)' } }}
				{...props}
			>
				<Stack
					data-testid="create-report-modal-id"
					sx={{ margin: '0 auto 27px auto', padding: '0 3rem', position: 'relative', maxWidth: 1400 }}
				>
					<Stack
						justifyContent="flex-end"
						direction="row"
						sx={{
							backgroundColor: 'primary.main',
							padding: 3.2,
							marginBottom: 2,
							position: 'sticky',
							top: 0,
							zIndex: 2,
							alignItems: 'center',
						}}
					>
						<Typography variant="h2" sx={{ marginRight: 'auto', color: 'background.paper' }}>
							<Tr.Common path="analysis-report" />
						</Typography>
						<Link sx={{ textDecoration: 'none' }} href={sendViaMail(resultData.nmrAnalysis.title)}>
							<Button
								variant="outlined"
								sx={{
									backgroundColor: 'background.paper',
									'&:hover': {
										backgroundColor: 'background.paper',
										borderWidth: '1px',
									},
								}}
								type="button"
							>
								<Tr.Portal path="analysis-result.share-via-email" />
							</Button>
						</Link>
						<Button
							variant="outlined"
							data-testid="download-button-id"
							disableElevation
							sx={{
								margin: '0 10px',
								backgroundColor: 'background.paper',
								'&:hover': {
									backgroundColor: 'background.paper',
									borderWidth: '1px',
								},
							}}
							disabled={buttonDisable}
							onClick={() => {
								if (pdfUrl) {
									const fileName = `${format(
										DateUtils.getZonedToUtcTime(resultData.confirmationDate),
										'yyyy-MM-dd HH.mm.ss',
									)}-${resultData.nmrAnalysis.title}.pdf`;
									downloadFileFromLink(pdfUrl, fileName);
								}
							}}
						>
							<Tr.Portal path="analysis-result.download-pdf" />
						</Button>
						<IconButton data-testid="close-icon-id" onClick={() => onClose()}>
							<Close sx={{ color: 'background.paper' }} />
						</IconButton>
					</Stack>
					{renderPdfs()}
				</Stack>
			</ModalDialog>
		</>
	);
};
