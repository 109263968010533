import { FormikApiType, FormikDatePicker, FormikTextFieldProps, FormikTextfield } from '@components/common';
import { GenericCard } from '@components/common/GenericCard';
import { NmrSolventList, SolventSampleWeightInput } from '@components/nmr-portal';
import { CalibrationList } from '@components/nmr-portal/CalibrationList';
import { useAnalysis } from '@hooks';
import { QualitativeOrQuantitative } from '@models';
import { getIsQuantitiveNonTargeted } from '@models';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { NmrAnalysis } from '@services';
import { DateUtils, Tr, TypeUtils } from '@utils';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const AnalysisDetailFormik: FC<FormikTextFieldProps> = ({ name, formikApi, inputProps, sx, ...props }) => (
	<FormikTextfield
		name={name}
		formikApi={formikApi}
		variant="outlined"
		fullWidth
		inputProps={{ maxLength: 256, ...inputProps }}
		sx={{ backgroundColor: 'primary.100', ...sx }}
		{...props}
	/>
);

type AnalysisDetailType = {
	measurementType?: QualitativeOrQuantitative;
	analysisData: Maybe<NmrAnalysis>;
	fileName: string;
	uploadedFile: boolean;
	formik: FormikApiType;
	isAnalysisProceedable?: boolean;
};
export const NmrAnalysisDetail: FC<AnalysisDetailType> = ({
	formik,
	fileName,
	analysisData,
	uploadedFile,
	isAnalysisProceedable = false,
}) => {
	const { t } = useTranslation('portal');
	const { measurementType, targetType, metaData, nmrDevice, isAnalysisEditable } = useAnalysis();

	const { '.SOLVENTNAME': solvent, LONGDATE: measurementDate, TITLE: measurementId, USERNAME: measurementExecuterId } = metaData || {};

	const checkFieldReadonly = (metaDataName?: string, analysisDataName?: string) => {
		if (!isAnalysisEditable) return true;
		if (!metaDataName && !analysisDataName) return false;

		if (uploadedFile) {
			return !!TypeUtils.returnValueOfKey(metaDataName, metaData ?? {});
		}
		return !!TypeUtils.returnValueOfKey(analysisDataName, analysisData ?? {});
	};

	const validateMeasurementDate = () => {
		if (uploadedFile) {
			return metaData && metaData.LONGDATE && DateUtils.isValidDate(new Date(metaData.LONGDATE));
		}
		return analysisData?.measurementDate && DateUtils.isValidDate(analysisData.measurementDate);
	};

	useEffect(() => {
		let formikValue = {
			solvent: solvent || '',
			measurementExecuterId: measurementExecuterId || '',
			measurementId: measurementId || '',
			measurementDeviceBrand: nmrDevice?.manufacturer || '',
			measurementDate:
				measurementDate && new Date(DateUtils.getMilliseconds(measurementDate)).getTime()
					? new Date(DateUtils.getMilliseconds(measurementDate))
					: null,
			title:
				fileName
					?.split('.')
					.filter((_, index) => index !== fileName?.split('.').length - 1)
					.join('.') || '',
			deviceCalibrationId: analysisData?.deviceCalibrationId,
		};

		if (!uploadedFile) {
			formikValue = {
				...formikValue,
				solvent: analysisData?.solvent || '',
				measurementExecuterId: analysisData?.measurementExecutorId || '',
				measurementId: analysisData?.measurementId || '',
				measurementDate: analysisData?.measurementDate || (null as any),
				title: analysisData?.title || '',
			};
		}

		formik?.resetForm({ values: { ...formik?.values, ...formikValue } });
		formik.validateForm({ ...formik?.values, ...formikValue });
	}, [metaData, uploadedFile, analysisData?.comment, nmrDevice]);

	const isQuantitiveNonTargeted = getIsQuantitiveNonTargeted(measurementType, targetType);

	if (!isAnalysisProceedable) {
		return null;
	}

	return (
		<GenericCard sx={{ borderTop: 'none', borderTopLeftRadius: 0, borderTopRightRadius: 0, pt: 0 }}>
			<Grid container columnSpacing={4}>
				<Grid item xs={12} sx={{ mb: 3 }}>
					<Divider sx={{ borderBottomWidth: 1, borderColor: 'grey.800', mb: 4, height: '1px' }} />
					<Typography variant="h4" color="grey.800">
						{measurementType === QualitativeOrQuantitative.QUALITATIVE ? (
							<Tr.Portal path="new-analysis.qualitative-analysis-details" />
						) : (
							<Tr.Portal path="new-analysis.quantitative-analysis-details" />
						)}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Stack direction="column" spacing={3}>
						<AnalysisDetailFormik
							formikApi={formik as FormikApiType}
							name="measurementId"
							data-testid="analysis-detail-measurement-id"
							title={t('new-analysis.measurement-id')}
							placeholder={t('new-analysis.measurement-id')}
							required
							sx={{ pointerEvents: checkFieldReadonly('TITLE', 'measurementId') ? 'none' : 'unset' }}
							disabled={checkFieldReadonly('TITLE', 'measurementId')}
							InputProps={{
								readOnly: checkFieldReadonly('TITLE', 'measurementId'),
							}}
						/>
						<AnalysisDetailFormik
							formikApi={formik as FormikApiType}
							data-testid="analysis-detail-title-id"
							name="title"
							title={t('new-analysis.analysis-title')}
							disabled={checkFieldReadonly()}
							placeholder={t('new-analysis.analysis-title')}
							required
						/>

						{formik?.values.measurementType === QualitativeOrQuantitative.QUANTITATIVE && (
							<CalibrationList
								formik={formik}
								name="deviceCalibrationId"
								title={t('device-management.calibration')}
								calibrationList={nmrDevice?.nmrDeviceCalibrations}
								disabled={checkFieldReadonly()}
							/>
						)}
						<FormikDatePicker
							name="measurementDate"
							formik={formik}
							format="dd/MM/yyyy"
							maxDate={Date.now()}
							disabled={!!validateMeasurementDate() || checkFieldReadonly()}
							required={!validateMeasurementDate() || !checkFieldReadonly()}
							showPickerIcon={!validateMeasurementDate()}
							onChange={(value) => {
								formik?.setFieldValue('measurementDate', value, true);
							}}
							title={t('new-analysis.measurement-date')}
							placeholder={t('new-analysis.measurement-date')}
							error={!!formik.errors.measurementDate}
							helperText={formik.errors.measurementDate?.toString()}
							data-testid="analysis-detail-date-id"
						/>

						{formik?.values.measurementType && (
							<NmrSolventList name="solvent" required formik={formik} disabled={!isAnalysisEditable} />
						)}
						{formik?.values.measurementType === QualitativeOrQuantitative.QUANTITATIVE && (
							<>
								{isQuantitiveNonTargeted && (
									<SolventSampleWeightInput
										valueChange={(val) => formik?.setFieldValue('molecularWeight', val)}
										value={formik?.values['molecularWeight']}
										title={t('new-analysis.moleculer-weight-in-g-mol')}
										placeholder={t('new-analysis.moleculer-weight')}
										inputProps={{ 'aria-label': 'molecular-weight-id' }}
										info={t('new-analysis.moleculer-weight-info')}
										prefix="[g/mol]"
										disabled={checkFieldReadonly()}
									/>
								)}
								<SolventSampleWeightInput
									valueChange={(val) => formik?.setFieldValue('sampleWeight', val)}
									disabled={checkFieldReadonly() || (!!isQuantitiveNonTargeted && !formik?.values['molecularWeight'])}
									value={formik?.values['sampleWeight']}
									title={t('new-analysis.sample-weight-in-milligram')}
									placeholder={t('new-analysis.sample-weight')}
									inputProps={{ 'aria-label': 'sample-weight-id' }}
									info={t('new-analysis.sample-weight-info')}
									prefix="[mg]"
								/>
								<SolventSampleWeightInput
									valueChange={(val) => formik?.setFieldValue('solventWeight', val)}
									disabled={checkFieldReadonly() || (!!isQuantitiveNonTargeted && !formik?.values['molecularWeight'])}
									value={formik?.values['solventWeight']}
									title={t('new-analysis.solvent-weight-in-milligram')}
									placeholder={t('new-analysis.solvent-weight')}
									inputProps={{ 'data-testid': 'solvent-weight-id' }}
									prefix="[mg]"
								/>
							</>
						)}
					</Stack>
				</Grid>
				<Grid item xs={6}>
					<Stack direction="column" spacing={3}>
						{formik?.values.measurementType === QualitativeOrQuantitative.QUANTITATIVE && (
							<AnalysisDetailFormik
								formikApi={formik as FormikApiType}
								name="measurementDeviceBrand"
								data-testid="analysis-detail-brand-id"
								title={t('new-analysis.measurement-device')}
								placeholder={t('new-analysis.measurement-device')}
								disabled={!!nmrDevice?.manufacturer || checkFieldReadonly()}
								required
								sx={{
									backgroundColor: nmrDevice?.manufacturer ? 'primary.100' : 'grey.50',
									pointerEvents: nmrDevice?.manufacturer ? 'none' : 'unset',
								}}
							/>
						)}

						<AnalysisDetailFormik
							formikApi={formik as FormikApiType}
							name="productAndBatchNumber"
							data-testid="analysis-detail-batch-id"
							title={t('new-analysis.product-and-batch-number')}
							placeholder={t('new-analysis.product-and-batch-number')}
							focused={false}
							disabled={checkFieldReadonly()}
							sx={{ backgroundColor: 'grey.50' }}
						/>
						<AnalysisDetailFormik
							formikApi={formik as FormikApiType}
							name="projectId"
							title={t('new-analysis.project-id')}
							data-testid="analysis-detail-project-id"
							placeholder={t('new-analysis.project-id')}
							focused={false}
							disabled={checkFieldReadonly()}
							sx={{ backgroundColor: 'grey.50' }}
						/>
						<AnalysisDetailFormik
							formikApi={formik as FormikApiType}
							name="measurementExecutorId"
							data-testid="analysis-detail-executor-id"
							title={t('new-analysis.executer-id')}
							placeholder={t('new-analysis.executer-id')}
							focused={false}
							InputProps={{
								readOnly: checkFieldReadonly('USERNAME', 'measurementExecuterId'),
							}}
							sx={{ backgroundColor: checkFieldReadonly('USERNAME', 'measurementExecuterId') ? 'primary.100' : 'grey.50' }}
							disabled={checkFieldReadonly('USERNAME', 'measurementExecuterId')}
						/>
						<AnalysisDetailFormik
							formikApi={formik as FormikApiType}
							name="details"
							data-testid="analysis-detail-details-id"
							title={t('new-analysis.additional-details')}
							placeholder={t('new-analysis.type-additional-note-here')}
							focused={false}
							multiline
							rows={4}
							sx={{ backgroundColor: 'grey.50' }}
							inputProps={{ maxLength: 4000 }}
							disabled={checkFieldReadonly()}
						/>
					</Stack>
				</Grid>
			</Grid>
		</GenericCard>
	);
};
