import { forwardRef, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Stack, StackProps, IconButtonProps, Typography } from '@mui/material';
import { Tr } from '@utils';
import { ZoomChild } from '../ZoomChild';
import SmilesDrawer from 'smiles-drawer';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
interface IMolecularFormulaProps {
	smilesFormula?: string;
	width?: number;
	height?: number;
	onAddFavorite?: () => void;
	isTheoretical?: boolean;
	deactivateColors?: boolean;
	zoomIconProps?: IconButtonProps & { 'data-testid': string | undefined | number };
	wrapperRef?: MutableRefObject<HTMLDivElement | null>;
	borderColor?: string;
}
export const MolecularFormula = forwardRef<HTMLDivElement, IMolecularFormulaProps & Omit<StackProps, 'height' | 'width' | 'ref'>>(
	({ smilesFormula, height, width, isTheoretical, deactivateColors = false, sx, zoomIconProps, borderColor, ...props }, ref) => {
		const [zoomEnabled, setZoomEnabled] = useState(false);
		const [counter, setCounter] = useState<number>(1);
		const wrapperRef = useRef<HTMLDivElement | null>(null);
		return (
			<Stack
				sx={{
					border: '2px solid',
					borderColor: deactivateColors ? 'grey.800' : isTheoretical ? 'info.700' : borderColor || 'primary.main',
					borderRadius: '0.5rem',
					backgroundColor: 'background.paper',
					overflow: 'hidden',
					position: 'relative',
					justifyContent: 'center',
					alignItems: 'center',
					minWidth: width,
					height: height,
					...sx,
					maxHeight: '100%',
					'& canvas': {
						visibility: zoomEnabled ? 'hidden' : 'visible',
					},
				}}
			>
				<ZoomChild
					fullScreen
					sx={{
						height: '100%',
					}}
					onZoomChange={(isBig) => {
						setCounter((prev) => prev + 1);

						const timeout = setTimeout(() => {
							setZoomEnabled(isBig);
						}, 100);

						return () => {
							clearTimeout(timeout);
						};
					}}
					isDeactivated={deactivateColors}
					isTheoretical={isTheoretical}
					zoomIconProps={zoomIconProps}
					iconColor={borderColor}
				>
					<Stack ref={wrapperRef} width={1} height={1} maxHeight={'100%'} key={counter.toString() + smilesFormula}>
						{useMemo(
							() => (
								<MoleculerFormulaDrawer
									smilesFormula={smilesFormula}
									width={wrapperRef.current?.offsetWidth}
									height={wrapperRef.current?.offsetHeight}
									wrapperRef={wrapperRef}
									ref={ref}
									{...props}
								/>
							),
							[ref, wrapperRef?.current?.offsetHeight, wrapperRef?.current?.offsetWidth],
						)}
					</Stack>
				</ZoomChild>
			</Stack>
		);
	},
);

const MoleculerFormulaDrawer = forwardRef<
	HTMLDivElement,
	Omit<IMolecularFormulaProps, 'isTheoretical' | 'isDeactivated' | 'onAddFavorite' | 'showStarBadge'> &
		Omit<StackProps, 'height' | 'width'>
>(({ smilesFormula, wrapperRef, height = 400, width, sx, ...props }, ref) => {
	const canvasRef = useRef<HTMLImageElement | null>(null);
	const [err, setErr] = useState<boolean>(false);
	const drawer = useMemo(
		() =>
			new SmilesDrawer.SmiDrawer({
				width: width ?? 350,
				height: height ?? 400,
				compactDrawing: false,
				themes: {
					light: {
						C: '#000',
						O: '#000',
						N: '#000',
						F: '#000',
						CL: '#000',
						BR: '#000',
						I: '#000',
						P: '#000',
						S: '#000',
						B: '#000',
						SI: '#000',
						H: '#000',
						BACKGROUND: '#fff',
					},
				},
			}),
		[width, height],
	);

	const drawImage = () => {
		drawer.draw(
			smilesFormula?.trim(),
			canvasRef.current,
			'light',
			() => undefined,
			() => setErr(true),
		);
	};
	useEffect(() => {
		if (smilesFormula && canvasRef.current && width && height && drawer) {
			drawImage();
		}
	}, [smilesFormula, canvasRef.current, drawer, width, height, wrapperRef?.current, ref]);

	// From version 2.1.7 of canvas && React 18+, canvas elements doesn't work anymore.
	// See: https://github.com/reymond-group/smilesDrawer/issues/167
	return (
		<>
			<Stack ref={ref} justifyContent="center" alignItems="center" height={1} width={1} sx={sx} {...props}>
				{smilesFormula && !err ? <img className="test4" ref={canvasRef} /> : <NoStructureAvailable />}
			</Stack>
		</>
	);
});

const NoStructureAvailable = () => {
	return (
		<Stack sx={{ alignItems: 'center', justifyContent: 'center' }} spacing={1}>
			<Stack
				width={80}
				height={80}
				sx={{ borderRadius: '50%', backgroundColor: 'grey.100', alignItems: 'center', justifyContent: 'center' }}
			>
				<ImageOutlinedIcon sx={{ height: 40, width: 40, color: 'grey.800' }} />
			</Stack>
			<Typography variant="pg-s" color="grey.800">
				<Tr.Common path="no-structure-available" />
			</Typography>
		</Stack>
	);
};
