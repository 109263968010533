import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { analyticsAnalysisEvent, RxUtils, Tr } from '@utils';
import { PortalPageRoutes } from '@models';
import { irAnalysisService } from '@services';
import { SectionWithSubtitle } from '@components/common';
import { Button, Stack, Typography } from '@mui/material';
import { MyAnalyses } from './MyAnalyses';
import { MostRecentCompletedAnalysis } from './MostRecentCompletedAnalysis';

export const IrDashboard: FC = () => {
	const navigate = useNavigate();

	const startNewAnalysis = () => {
		RxUtils.promisify(irAnalysisService.create(), (data) => {
			analyticsAnalysisEvent.AnalysisCreated(`${data.id}`);
			navigate(`../${PortalPageRoutes.ANALYSIS}/${data.id}`);
		});
	};

	return (
		<Stack data-testid="dashboard-wrapper-id" direction="column" spacing={5}>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography variant="h1" sx={{ color: 'text.secondary', fontSize: '2rem', fontWeight: 700 }}>
					<Tr.IrPortal path="dashboard.title" />
				</Typography>
				<Button
					data-testid="start-new-analyze-button-id"
					variant="contained"
					size="medium"
					disableElevation
					onClick={startNewAnalysis}
					sx={{
						width: '7.5rem',
						height: '2.5rem',
					}}
				>
					<Tr.IrPortal path="dashboard.new-analysis" />
				</Button>
			</Stack>
			<SectionWithSubtitle
				subtitle={
					<Typography sx={{ fontSize: '1.625rem', fontWeight: 700, color: 'text.secondary' }}>
						<Tr.IrPortal path="dashboard.my-analyses.title" />
					</Typography>
				}
			>
				<MyAnalyses />
			</SectionWithSubtitle>
			<MostRecentCompletedAnalysis />
		</Stack>
	);
};
