import { FormikApiType, FormikDatePicker, FormikTextFieldProps, FormikTextfield } from '@components/common';
import { GenericCard } from '@components/common/GenericCard';
import { AccessoryListForIr, PeakRangeInput } from '@components/ir-portal';
import { DeviceListForIr } from '@components/ir-portal/DeviceListForIr';
import { useIrAnalysis } from '@hooks';
import { AnalysisType } from '@models';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { IrAnalysis } from '@services';
import { DateUtils, Tr, TypeUtils } from '@utils';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AnalysisDetailFormik: FC<FormikTextFieldProps> = ({ name, formikApi, inputProps, sx, ...props }) => (
	<FormikTextfield
		name={name}
		formikApi={formikApi}
		variant="outlined"
		focused
		fullWidth
		inputProps={{ maxLength: 256, ...inputProps }}
		sx={{ backgroundColor: 'primary.100', ...sx }}
		{...props}
	/>
);

type AnalysisDetailType = {
	analysisType?: AnalysisType;
	analysisData: Maybe<IrAnalysis>;
	fileName: string;
	uploadedFile: boolean;
	formik: FormikApiType;
};
// TO DO: TIMERS SHOULD BE IN DIFFERENT COMPONENT !
export const IrAnalysisDetail: FC<AnalysisDetailType> = ({ formik, fileName, analysisData, uploadedFile }) => {
	const { t } = useTranslation('irportal');

	const { metaData, irDevice, isAnalysisEditable, updatePeaksChanged, isPeaksChanged } = useIrAnalysis();
	const { USERNAME: measurementExecuterId } = metaData || {};
	const [comparestatus, setCompareStatus] = useState(false);
	const [comparestatus2, setCompareStatus2] = useState(false);
	const [timers, setTimers] = useState<{ [key: string]: NodeJS.Timeout | null }>({
		peakMin1: null,
		peakMax1: null,
		peakMin2: null,
		peakMax2: null,
	});
	const date = (metaData && metaData['date']) || '';

	const noInputError = 'Peak value is required';

	const checkFieldReadonly = (metaDataName?: string, analysisDataName?: string) => {
		if (!isAnalysisEditable) return true;

		if (!metaDataName && !analysisDataName) return false;
		if (uploadedFile) {
			return !!TypeUtils.returnValueOfKey(metaDataName, metaData ?? {});
		}
		return !!TypeUtils.returnValueOfKey(analysisDataName, analysisData ?? {});
	};

	const validateMeasurementDate = () => {
		if (uploadedFile) {
			return (
				date &&
				!!new Date(DateUtils.getMilliseconds(date)).getTime() &&
				new Date(DateUtils.getMilliseconds(date)).getTime() <= new Date().getTime()
			);
		}
		return analysisData?.measurementDate;
	};

	useEffect(() => {
		let formikValue = {
			device: '',
			accessory: '',
			technology: 'IR',
			measurementExecuterId: measurementExecuterId || '',
			measurementId: analysisData?.measurementId,
			measurementDeviceBrand: irDevice?.manufacturer || '',
			measurementDate:
				date && new Date(DateUtils.getMilliseconds(date)).getTime() && new Date().getTime()
					? new Date(DateUtils.getMilliseconds(date))
					: null,
			title:
				fileName
					?.split('.')
					.filter((_, index) => index !== fileName?.split('.').length - 1)
					.join('.') || '',
		};

		if (!uploadedFile) {
			formikValue = {
				...formikValue,

				device: analysisData?.device || '',
				accessory: analysisData?.accessory || '',
				measurementExecuterId: analysisData?.measurementExecutorId || '',
				measurementId: analysisData?.measurementId || '',
				measurementDate:
					date && new Date(DateUtils.getMilliseconds(date)).getTime() && new Date().getTime()
						? new Date(DateUtils.getMilliseconds(date))
						: null,
				title: analysisData?.title || '',
				technology: 'IR',
			};
		}

		formik?.resetForm({ values: { ...formik?.values, ...formikValue } });
		formik.validateForm({ ...formik?.values, ...formikValue });
	}, [metaData, uploadedFile, analysisData?.comment, irDevice]);

	const handleValidation = (name: string, value: number) => {
		formik.setFieldValue(name, value);

		const validateRange = (minValue: number, maxValue: number, setStatus: (status: boolean) => void) => {
			if (maxValue <= minValue || maxValue - minValue <= 5 || maxValue - minValue >= 200) {
				setStatus(true);
			} else {
				setStatus(false);
			}
		};

		if (name === 'peakMin1') {
			if (formik.values.peakMax1) {
				validateRange(value, formik.values.peakMax1, setCompareStatus);
			}
		} else if (name === 'peakMax1') {
			validateRange(formik.values.peakMin1, value, setCompareStatus);
		} else if (name === 'peakMin2') {
			if (formik.values.peakMax2) {
				validateRange(value, formik.values.peakMax2, setCompareStatus2);
			}
		} else if (name === 'peakMax2') {
			validateRange(formik.values.peakMin2, value, setCompareStatus2);
		}
	};

	const onInputChange = (name: string, value) => {
		if (formik.values[name as keyof typeof formik.values] === value) return;
		if (timers[name as keyof typeof timers]) {
			clearTimeout(timers[name as keyof typeof timers] as NodeJS.Timeout);
		}

		const newTimer = setTimeout(() => {
			handleValidation(name, value);
		}, 1000);

		setTimers((prevTimers) => ({ ...prevTimers, [name]: newTimer }));
	};

	return (
		<GenericCard sx={{ borderTop: 'none', borderTopLeftRadius: 0, borderTopRightRadius: 0, pt: 0 }}>
			<Grid container columnSpacing={20}>
				<Grid item xs={12} sx={{ mb: 3 }}>
					<Divider sx={{ borderBottomWidth: 2, borderColor: 'grey.200', mb: 4 }} />
					<Typography variant="h3">
						<Tr.IrPortal path="new-analysis.identification-analysis-details" />
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Stack direction="column" spacing={4}>
						<AnalysisDetailFormik
							formikApi={formik as FormikApiType}
							disabled={checkFieldReadonly()}
							name="title"
							data-testid="analysis-detail-detail-title-id"
							title={t('new-analysis.analysis-title')}
							placeholder={t('new-analysis.analysis-title')}
							required
						/>
						{formik?.values.type && (
							<DeviceListForIr name="deviceId" required={false} formik={formik} disabled={checkFieldReadonly()} />
						)}
						{formik?.values.type && (
							<AccessoryListForIr name="accessoryId" required={false} formik={formik} disabled={checkFieldReadonly()} />
						)}

						<FormikDatePicker
							name="measurementDate"
							formik={formik}
							format="dd/MM/yyyy"
							maxDate={Date.now()}
							disabled={!!validateMeasurementDate() || checkFieldReadonly()}
							required={!validateMeasurementDate() || !checkFieldReadonly()}
							showPickerIcon={!validateMeasurementDate()}
							onChange={(value) => {
								formik?.setFieldValue('measurementDate', value, true);
							}}
							title={t('new-analysis.measurement-date')}
							placeholder={t('new-analysis.measurement-date')}
							error={!!formik.errors.measurementDate}
							helperText={formik.errors.measurementDate?.toString()}
							data-testid="analysis-detail-date-id"
						/>
					</Stack>
				</Grid>
				<Grid item xs={6}>
					<Stack direction="column" spacing={4}>
						<AnalysisDetailFormik
							formikApi={formik as FormikApiType}
							name="measurementId"
							title={t('new-analysis.measurement-id')}
							placeholder={t('new-analysis.measurement-id')}
							sx={{ pointerEvents: checkFieldReadonly('TITLE', 'measurementId') ? 'none' : 'unset' }}
							disabled={true}
							focused={false}
							inputProps={{ readOnly: true }}
						/>
						<AnalysisDetailFormik
							formikApi={formik as FormikApiType}
							name="productAndBatchNumber"
							data-testid="analysis-detail-batch-id"
							title={t('new-analysis.product-and-batch-number')}
							placeholder={t('new-analysis.product-and-batch-number')}
							focused={false}
							disabled={checkFieldReadonly()}
							sx={{ backgroundColor: 'grey.50' }}
						/>

						<AnalysisDetailFormik
							formikApi={formik as FormikApiType}
							name="projectId"
							data-testid="analysis-detail-project-id"
							title={t('new-analysis.project-id')}
							placeholder={t('new-analysis.project-id')}
							focused={false}
							disabled={checkFieldReadonly()}
							sx={{ backgroundColor: 'grey.50' }}
						/>

						<AnalysisDetailFormik
							formikApi={formik as FormikApiType}
							name="measurementExecutorId"
							title={t('new-analysis.executer-id')}
							placeholder={t('new-analysis.executer-id')}
							disabled={checkFieldReadonly()}
							data-testid="analysis-detail-executor-id"
							focused={false}
							InputProps={{
								readOnly: checkFieldReadonly('USERNAME', 'measurementExecuterId'),
							}}
							sx={{ backgroundColor: checkFieldReadonly('USERNAME', 'measurementExecuterId') ? 'primary.100' : 'grey.50' }}
						/>

						<AnalysisDetailFormik
							formikApi={formik as FormikApiType}
							name="details"
							title={t('new-analysis.additional-details')}
							data-testid="analysis-detail-details-id"
							placeholder={t('new-analysis.type-additional-note-here')}
							disabled={checkFieldReadonly()}
							focused={false}
							multiline
							rows={4}
							sx={{ backgroundColor: 'grey.50' }}
							inputProps={{ maxLength: 4000 }}
						/>
					</Stack>
				</Grid>
				{formik?.values.type === AnalysisType.QUANTIFICATION && (
					<>
						<Grid item xs={12} sx={{ mb: 3 }}>
							<Divider sx={{ borderBottomWidth: 3, borderColor: 'grey.200', my: 4 }} />
							<Typography variant="h3">
								<Tr.IrPortal path="new-analysis.quantification-analysis-details" />
							</Typography>
						</Grid>
						<Grid item xs={12} sx={{ mb: 3 }}>
							<Typography variant="body1">
								<Tr.IrPortal path="new-analysis.quantification-analysis-sub-details" />
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="h3" sx={{ mb: 4 }}>
								<Tr.IrPortal path="new-analysis.quantification-analysis-peak1" />
							</Typography>
							<Stack direction="row" spacing={4}>
								<PeakRangeInput
									valueChange={(val) => {
										onInputChange('peakMin1', val);
										updatePeaksChanged({
											...isPeaksChanged,
											peakMin1: true,
										});
									}}
									value={formik?.values['peakMin1']}
									name="peakMin1"
									title={t('new-analysis.peak-label-from')}
									placeholder={t('new-analysis.peak-placeholder-from')}
									inputProps={{ 'aria-label': 'peak-range-id' }}
									suffixforNumeric={true}
									rangeMax={3900}
									isRequiredField={true}
									onBlur={() => formik.setFieldTouched('peakMin1', true)}
									disabled={checkFieldReadonly()}
									sx={{ width: 200 }}
								/>

								<PeakRangeInput
									valueChange={(val) => {
										onInputChange('peakMax1', val);
										updatePeaksChanged({
											...isPeaksChanged,
											peakMax1: true,
										});
									}}
									value={formik?.values['peakMax1']}
									name="peakMax1"
									title={t('new-analysis.peak-label-to')}
									placeholder={t('new-analysis.peak-placeholder-to')}
									inputProps={{ 'aria-label': 'peak-range-id' }}
									suffixforNumeric={true}
									rangeMax={3900}
									isRequiredField={true}
									onBlur={() => formik.setFieldTouched('peakMax1', true)}
									disabled={checkFieldReadonly()}
								/>
							</Stack>
							<Stack direction="row" sx={{ position: 'relative' }}>
								{/* TO DO: WOW! CREATE NEW COMPONENTS WRT SOLID PRINCIPLES. */}
								{/* TO DO: ALSO, WHAT DOES 650 MEANS? */}
								{comparestatus && formik?.values.peakMin1 > 650 && formik?.values.peakMax1 > 650 && (
									<Typography
										variant="pg-s"
										color={'error.main'}
										sx={{ maxWidth: 430, marginTop: 1 }}
										marginLeft={formik?.errors.peakMin1 ? '0' : '0'}
									>
										<Tr.IrPortal path="new-analysis.peak-error" />
									</Typography>
								)}

								{formik.touched.peakMin1 &&
									formik?.values.peakMin1 &&
									formik?.errors.peakMin1 &&
									formik?.values.peakMin1 <= 650 && (
										<Typography variant="pg-s" color={'error.main'} sx={{ maxWidth: 200 }}>
											{formik?.errors.peakMin1.toString() === noInputError
												? !formik?.values.peakMin1 && ''
												: formik?.errors.peakMin1.toString()}
										</Typography>
									)}

								{formik.touched.peakMax1 &&
									formik?.values.peakMin1 &&
									formik?.errors.peakMax1 &&
									formik?.values.peakMax1 &&
									formik?.values.peakMax1 <= 650 &&
									!formik?.errors.peakMin1 && (
										<Typography
											variant="pg-s"
											color={'error.main'}
											// marginLeft={
											// 	formik?.errors.peakMin1
											// 		? formik?.errors.peakMin1 === noInputError
											// 			? '14.5rem'
											// 			: '2rem'
											// 		: '12.5rem'
											// }
											sx={{ maxWidth: 200, position: 'absolute', left: '232px', whiteSpace: 'noWrap' }}
										>
											{formik?.errors.peakMax1.toString() === noInputError
												? !formik?.values.peakMax1 && formik?.errors.peakMax1.toString()
												: formik?.errors.peakMax1.toString()}
										</Typography>
									)}

								{formik.touched.peakMax1 &&
									formik.touched.peakMin1 &&
									formik?.values.peakMin1 &&
									formik?.values.peakMin1 <= 650 &&
									formik?.errors.peakMax1 &&
									formik?.values.peakMax1 &&
									formik?.values.peakMax1 <= 650 &&
									formik?.errors.peakMin1 && (
										<Typography
											variant="pg-s"
											color={'error.main'}
											// marginLeft={
											// 	formik?.errors.peakMin1
											// 		? formik?.errors.peakMin1 === noInputError
											// 			? '14.5rem'
											// 			: '2rem'
											// 		: '50%'
											// }
											sx={{ maxWidth: 200, position: 'absolute', left: '232px', whiteSpace: 'noWrap' }}
										>
											{formik?.errors.peakMax1.toString() === noInputError
												? !formik?.values.peakMax1
												: formik?.errors.peakMax1.toString()}
										</Typography>
									)}

								{formik.touched.peakMin1 &&
									formik?.values.peakMax1 &&
									!formik?.values.peakMin1 &&
									formik?.errors.peakMin1 === noInputError && (
										<Typography variant="pg-s" color={'error.main'} sx={{ maxWidth: 200 }}>
											{formik?.errors.peakMin1.toString()}
										</Typography>
									)}

								{formik.touched.peakMax1 &&
									!formik?.values.peakMax1 &&
									formik?.values.peakMin1 &&
									formik?.errors.peakMax1 &&
									formik?.errors.peakMin1 !== noInputError && (
										<Typography
											variant="pg-s"
											color={'error.main'}
											sx={{ maxWidth: 200, position: 'absolute', left: '232px', whiteSpace: 'noWrap' }}
											// sx={{ maxWidth: 200 }}
										>
											{formik?.values.peakMin1 > 650
												? !formik?.errors.peakMin1 && formik?.errors.peakMax1.toString()
												: formik?.errors.peakMax1.toString()}
										</Typography>
									)}

								{formik.touched.peakMax1 &&
									formik?.values.peakMax1 &&
									!formik?.values.peakMin1 &&
									formik?.errors.peakMax1 &&
									formik?.errors.peakMin1 === noInputError && (
										<Typography
											variant="pg-s"
											color={'error.main'}
											sx={{ maxWidth: 200 }}
											marginLeft={formik?.errors.peakMin1 && formik?.touched.peakMin1 ? '6rem' : '14.5rem'}
										>
											{formik?.errors.peakMax1.toString() === noInputError
												? !formik?.values.peakMax1 && formik?.errors.peakMax1.toString()
												: formik?.errors.peakMax1.toString()}
										</Typography>
									)}

								{formik.touched.peakMin1 &&
									!formik?.values.peakMin1 &&
									formik?.errors.peakMin1 === noInputError &&
									!formik?.values.peakMax1 && (
										<Typography variant="pg-s" color={'error.main'} sx={{ maxWidth: 200 }}>
											{formik?.errors.peakMin1.toString()}
										</Typography>
									)}

								{formik.touched.peakMax1 &&
									!formik?.values.peakMax1 &&
									formik?.errors.peakMax1 === noInputError &&
									!formik?.values.peakMin1 && (
										<Typography marginLeft="6rem" variant="pg-s" color={'error.main'} sx={{ maxWidth: 200 }}>
											{formik?.errors.peakMin1 === noInputError &&
												!formik.values.peakMin1 &&
												formik?.errors.peakMax1.toString()}
										</Typography>
									)}
							</Stack>
						</Grid>
						{formik.values.quantificationMethod === 'PeakRatio' && (
							<Grid item xs={6}>
								<Typography variant="h3" sx={{ mb: 4 }}>
									<Tr.IrPortal path="new-analysis.quantification-analysis-peak2" />
								</Typography>
								<Stack direction="row" spacing={4}>
									<PeakRangeInput
										valueChange={(val) => {
											onInputChange('peakMin2', val);
											updatePeaksChanged({
												...isPeaksChanged,
												peakMin2: true,
											});
										}}
										value={formik?.values['peakMin2']}
										title={t('new-analysis.peak-label-from')}
										placeholder={t('new-analysis.peak-placeholder-from')}
										inputProps={{ 'aria-label': 'peak-range-id' }}
										suffixforNumeric={true}
										rangeMax={3900}
										isRequiredField={true}
										onBlur={() => formik.setFieldTouched('peakMin2', true)}
									/>

									<PeakRangeInput
										valueChange={(val) => {
											onInputChange('peakMax2', val);
											updatePeaksChanged({
												...isPeaksChanged,
												peakMax2: true,
											});
										}}
										value={formik?.values['peakMax2']}
										title={t('new-analysis.peak-label-to')}
										placeholder={t('new-analysis.peak-placeholder-to')}
										inputProps={{ 'data-testid': 'peak-range-id' }}
										suffixforNumeric={true}
										rangeMax={3900}
										isRequiredField={true}
										onBlur={() => formik.setFieldTouched('peakMax2', true)}
									/>
								</Stack>
								<Stack direction="row">
									{comparestatus2 && formik?.values.peakMin2 > 650 && formik?.values.peakMax2 > 650 && (
										<Typography
											variant="pg-s"
											color={'error.main'}
											sx={{ maxWidth: 430, marginTop: 1 }}
											marginLeft={formik?.errors.peakMin2 ? '0' : '0'}
										>
											<Tr.IrPortal path="new-analysis.peak-error" />
										</Typography>
									)}

									{formik.touched.peakMin2 &&
										formik?.values.peakMin2 &&
										formik?.errors.peakMin2 &&
										formik?.values.peakMin2 <= 650 && (
											<Typography variant="pg-s" color={'error.main'} sx={{ maxWidth: 200 }}>
												{formik?.errors.peakMin2.toString() === noInputError
													? !formik?.values.peakMin2 && formik?.errors.peakMin2.toString()
													: formik?.errors.peakMin2.toString()}
											</Typography>
										)}

									{formik.touched.peakMax2 &&
										formik?.values.peakMin2 &&
										formik?.errors.peakMax2 &&
										formik?.values.peakMax2 &&
										formik?.values.peakMax2 <= 650 &&
										!formik?.errors.peakMin2 && (
											<Typography
												variant="pg-s"
												color={'error.main'}
												marginLeft={
													formik?.errors.peakMin2
														? formik?.errors.peakMin2 === noInputError
															? '14.5rem'
															: '2rem'
														: '14.5rem'
												}
												sx={{ maxWidth: 200 }}
											>
												{formik?.errors.peakMax2.toString() === noInputError
													? !formik?.values.peakMax2 && formik?.errors.peakMax2.toString()
													: formik?.errors.peakMax2.toString()}
											</Typography>
										)}

									{formik.touched.peakMax2 &&
										formik.touched.peakMin2 &&
										formik?.values.peakMin2 &&
										formik?.values.peakMin2 <= 650 &&
										formik?.errors.peakMax2 &&
										formik?.values.peakMax2 &&
										formik?.values.peakMax2 <= 650 &&
										formik?.errors.peakMin2 && (
											<Typography
												variant="pg-s"
												color={'error.main'}
												marginLeft={
													formik?.errors.peakMin2
														? formik?.errors.peakMin2 === noInputError
															? '14.5rem'
															: '2rem'
														: '14.5rem'
												}
												sx={{ maxWidth: 200 }}
											>
												{formik?.errors.peakMax2.toString() === noInputError
													? !formik?.values.peakMax2
													: formik?.errors.peakMax2.toString()}
											</Typography>
										)}

									{formik.touched.peakMin2 &&
										formik?.values.peakMax2 &&
										!formik?.values.peakMin2 &&
										formik?.errors.peakMin2 === noInputError && (
											<Typography variant="pg-s" color={'error.main'} sx={{ maxWidth: 200 }}>
												{formik?.errors.peakMin2.toString()}
											</Typography>
										)}

									{formik.touched.peakMax2 &&
										!formik?.values.peakMax2 &&
										formik.values.peakMin2 &&
										formik?.errors.peakMax2 &&
										formik?.errors.peakMin2 !== noInputError && (
											<Typography
												variant="pg-s"
												color={'error.main'}
												marginLeft={formik?.errors.peakMin2 ? '2rem' : '14.5rem'}
												sx={{ maxWidth: 200 }}
											>
												{formik?.values.peakMin2 > 650
													? !formik?.errors.peakMin2 && formik?.errors.peakMax2.toString()
													: formik?.errors.peakMax2.toString()}
											</Typography>
										)}

									{formik.touched.peakMax2 &&
										formik?.values.peakMax2 &&
										!formik.values.peakMin2 &&
										formik?.errors.peakMax2 &&
										formik?.errors.peakMin2 === noInputError && (
											<Typography
												variant="pg-s"
												color={'error.main'}
												marginLeft={formik?.errors.peakMin2 && formik.touched.peakMin2 ? '6rem' : '15rem'}
												sx={{ maxWidth: 200 }}
											>
												{formik?.errors.peakMax2.toString() === noInputError
													? !formik?.values.peakMax2 && formik?.errors.peakMax2.toString()
													: formik?.errors.peakMax2.toString()}
											</Typography>
										)}

									{formik.touched.peakMin2 &&
										!formik?.values.peakMin2 &&
										formik?.errors.peakMin2 === noInputError &&
										!formik?.values.peakMax2 && (
											<Typography variant="pg-s" color={'error.main'} sx={{ maxWidth: 200 }}>
												{formik?.errors.peakMin2.toString()}
											</Typography>
										)}

									{formik.touched.peakMax2 &&
										!formik?.values.peakMax2 &&
										formik?.errors.peakMax2 === noInputError &&
										!formik?.values.peakMin2 && (
											<Typography marginLeft="6rem" variant="pg-s" color={'error.main'} sx={{ maxWidth: 200 }}>
												{formik?.errors.peakMin2 === noInputError &&
													!formik.values.peakMin2 &&
													formik?.errors.peakMax2.toString()}
											</Typography>
										)}
								</Stack>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</GenericCard>
	);
};
