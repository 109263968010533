import { MetaDataType } from '@hooks';
import { AnalysisTargetType, QualitativeOrQuantitative } from '@models/analysis';
import { NmrDevice, NmrSubstance } from '@services';
import { QuantitivePeakType } from '@services/nmr/nmr-analysis/nmr-quantitive-spectrum.class';

export enum QuantitativeStep {
	STEP_1 = 'Step1',
	STEP_2 = 'Step2',
}

export interface IProps {
	addedERMs: NmrSubstance[];
	updateAddedERMs: (type?: NmrSubstance[]) => void;
	quantitativeStep: Maybe<QuantitativeStep>;
	updateQuantitativeStep: (type?: Maybe<QuantitativeStep>) => void;
	nmrDevice: Maybe<NmrDevice>;
	updateNmrDevice: (type?: Maybe<NmrDevice>) => void;
	measurementType?: Maybe<QualitativeOrQuantitative>;
	updateMeasurementType: (type?: Maybe<QualitativeOrQuantitative>) => void;
	targetType?: Maybe<AnalysisTargetType>;
	updateTargetType: (type?: Maybe<AnalysisTargetType>) => void;
	metaData?: Maybe<MetaDataType>;
	updateMetaData: (type: Maybe<MetaDataType>) => void;
	changedPeaks: Partial<QuantitivePeakType>[];
	updateChangedPeaks: (type?: Partial<QuantitivePeakType>[]) => void;
	isAnalysisEditable?: Maybe<boolean>;
	updateAnalysisEditable: (type?: Maybe<boolean>) => void;
}
