import { IUploadedFile, PagedResult, PatchType } from '@models';
import { httpService, logger } from '@services/core';
import { RxUtils, TypeUtils } from '@utils';
import { concatMap, map, tap } from 'rxjs';
import { irConfig } from '../irConfig';
import { IrExportDrmModel } from './export-drm-model.interface';
import { IrDrmDetail } from './ir-drm-detail.class';
import { IrDRM } from './ir-drm.class';
import { IrSubstanceDetail } from './ir-substance-detail.class';
import { UserReferenceModel } from '../ir-user-reference';
class IrDrmService {
	private getUrl() {
		return `${irConfig.url}/ReferenceMaterial`;
	}

	get(id: number) {
		return httpService.get<IrDrmDetail>(this.getUrl(), `${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(IrDrmDetail, result)),
		);
	}

	getBySearchParams(params: URLSearchParams) {
		return httpService.get<PagedResult<IrDrmDetail>>(this.getUrl(), '', { errorHandler: 'notification', params: params }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(PagedResult<IrDrmDetail>, result)),
		);
	}

	getSubstancesbyLibrary(params: URLSearchParams) {
		return httpService
			.get<PagedResult<IrDRM>>(`${this.getUrl()}/rm`, ``, {
				params: params,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getSubstancesbyLibrary.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<IrDRM>(IrDRM), result)),
			);
	}

	getSubstanceDetail(id: number) {
		return httpService.get<IrSubstanceDetail>(this.getUrl(), `${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(IrSubstanceDetail, result)),
		);
	}

	getERMFile(id: number) {
		return httpService
			.get<string>(this.getUrl(), `${id}/files`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.getERMFile.name}]`, result)));
	}

	edit(drm: IrDrmDetail) {
		return httpService.put<IrDrmDetail>(this.getUrl(), `${drm.id}`, { body: drm, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.edit.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(IrDrmDetail, result)),
		);
	}

	updateErmActivity(id: number, comment: string, value: boolean) {
		const deActive = { op: PatchType.REPLACE, path: '/IsActive', value: value };
		const reason = { op: PatchType.REPLACE, path: '/Comment', value: comment };
		return httpService
			.patch(this.getUrl(), `${id}`, { body: [deActive, reason], errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.updateErmActivity.name}]`, result)));
	}

	exportAsCsv({ query, filteringData }: IrExportDrmModel) {
		const params = new URLSearchParams();
		params.append('query', `${query || ''}`);

		filteringData?.forEach((item) => {
			const entry = Object.entries(item as {})[0];
			entry[0] && entry[1] && params.append(entry[0], `${entry[1]}`);
		});

		return httpService
			.get<Blob>(this.getUrl(), `export`, {
				params: params,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.exportAsCsv.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			);
	}

	createUserReference(params) {
		return httpService.post(this.getUrl(), '', { body: params, errorHandler: 'notification', params: params }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	getSpectrumData(referenceMaterialId: number) {
		return httpService.get(this.getUrl(), `${referenceMaterialId}/spectrumdata`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	updateUserReference(reference: UserReferenceModel) {
		return httpService.put(this.getUrl(), ``, { body: reference, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.edit.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	uploadReferenceFile(id: number, file: IUploadedFile) {
		const formData = new FormData();
		formData.append('data', file.file);
		return httpService
			.post<string>(this.getUrl(), `${id}/files`, { body: formData, errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.getERMFile.name}]`, result)));
	}

	fetchUserReference(referenceMaterialId: number) {
		return httpService.get<UserReferenceModel>(this.getUrl(), `${referenceMaterialId}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.fetchUserReference.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(UserReferenceModel, result)),
		);
	}

	changeUserReferenceStatus(referenceMateriaId: number | undefined, status: string, comment?: string) {
		const payload = [
			{
				op: PatchType.REPLACE,
				path: '/status',
				value: status,
			},
		];
		if (comment) {
			payload.push({
				op: PatchType.REPLACE,
				path: '/comment',
				value: comment,
			});
		}

		return httpService.patch(this.getUrl(), `${referenceMateriaId}`, {
			body: payload,
			errorHandler: 'notification',
		});
	}

	deleteUserReference(referenceMateriaId: number) {
		return httpService.delete(this.getUrl(), `${referenceMateriaId}`, { errorHandler: 'notification' });
	}
}

export const irDrmService = new IrDrmService();
